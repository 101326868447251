import { CrushingMaterial } from './../Object_Classes/CrushingMaterial/CrushingMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { CrushCode } from '../Object_Classes/CrushingMaterial/CrushCode';

export class CrushingMaterialDB_controller {

  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();
  async getCurshingMaterialList(): Promise<CrushingMaterial[]> {
    let CrushingMaterialList: CrushingMaterial[] = [];
    var snapshot = await this.db.database.ref('CrushingMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const crush = new CrushingMaterial();
        crush.ID = childSnapshot.key;
        crush.MaterialBatch = childSnapshot.child('MaterialBatch').val();
        crush.CrushCode = childSnapshot.child('CrushCode').val();
        crush.Pigment = childSnapshot.child('Pigment').val();
        crush.Stock = childSnapshot.child('Stock').val();
        crush.RawMaterial = childSnapshot.child('RawMaterial').val();
        crush._Updated_By = childSnapshot.child('_Updated_By').val();
        crush._Created_By = childSnapshot.child('_Created_By').val();
        crush._Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        crush._Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        crush.PaletteNumber = childSnapshot.child('PaletteNumber').val();
        crush.ColourPlain = childSnapshot.child('ColourPlain').val();
        crush.Quantity = childSnapshot.child('Quantity').val();

        CrushingMaterialList.push(crush);
      });
    }
    return CrushingMaterialList;
  }

  addCrushingMaterial(Crush: CrushingMaterial, email: string) {
    var updates = {}
    const id = uuidv4();
    updates['/' + id + '/'] = Crush;
    Crush._Created_Date = new Date();
    Crush._Last_Updated = new Date();
    Crush._Created_By = email;
    Crush._Updated_By = email;
    this.db.database.ref('CrushingMaterial').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: Crush.RawMaterial + ' has been added by the ' + email,
      date: new Date()
    };

    this.firestore.collection('CrushingMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('CrushingMaterialLog').doc(dateFormat).collection('Raw').add(info);
  }

  updateCrushingMaterial(Crush: CrushingMaterial, email: string) {
    var updates = {}
    updates['/' + Crush.ID + '/'] = Crush;
    Crush._Last_Updated = new Date();
    Crush._Updated_By = email;
    this.db.database.ref('CrushingMaterial').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: Crush.RawMaterial + ' has been updated by the ' + email,
      date: new Date()
    };
    this.firestore.collection('CrushingMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('CrushingMaterialLog').doc(dateFormat).collection('Raw').add(info);
  }


  deleteCrushingMaterial(id: string) {
    this.db.database.ref('/CrushingMaterial/' + id).set(null);
  }

  async getCrushCodeList(): Promise<CrushCode[]> {
    let CrushCodeList: CrushCode[] = [];
    var snapshot = await this.db.database.ref('CrushCode').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const crush = new CrushCode();
        crush.ID = childSnapshot.key;
        crush.Code = childSnapshot.child('Code').val();
        CrushCodeList.push(crush);
      });
    }
    return CrushCodeList;
  }

  async addCode(type){
    var updates = {}
    const id = uuidv4();
    updates['/' + id + '/Code'] = type;
    this.db.database.ref('CrushCode').update(updates);
  }

  async deleteCode(id){
      this.db.database.ref('/CrushCode/' + id).set(null);
    }

}
