import { AddPOComponent } from './add-po/add-po.component';
import { ScheduleTracker } from './../../Services/Object_Classes/Machine/Machine';
import { AngularFirestore } from '@angular/fire/firestore';
import { ShippingInformationComponent } from './shipping-information/shipping-information.component';
import { RawCheckOutInformationComponent } from './raw-check-out-information/raw-check-out-information.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { OrdersComponent } from '../raw/PO/orders/orders.component';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddCustomerComponent } from './Customer/add-customer/add-customer.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { DetailPOPageComponent } from './detail-po/detail-po-page.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { DowntimeDetailsComponent } from './downtime-details/downtime-details.component';
import { JobTubingDetailsComponent } from './job-tubing-details/job-tubing-details.component';
import { AddScheduleComponent } from '../scheduling/add-schedule/add-schedule.component';

@Component({
  selector: 'app-purchaseOrder',
  templateUrl: './purchaseOrder.component.html',
  styleUrls: ['./purchaseOrder.component.css']
})
export class PurchaseOrderComponent implements OnInit {
  POno: string;
  partNo: string;
  POquantity: number;
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  email: string;

  POlist: PurchaseOrder[] = [];
  ClonePOList: PurchaseOrder[] = [];
  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  //Pagination Customer
  lengthcus = 0;
  pageSizecus  = 10;
  pageSizeOptionscus : number[] = [10, 25, 50];
  pageIndexcus  = 0;
  offsetcus  = this.pageSizecus  * this.pageIndexcus ;
  searchcus : string;
  sorteducus  = {
    active: '',
    direction: ''
  };
  Customer: Customer[] = [];
  CloneCustomer: Customer[] = [];
  @ViewChild('topPaginatorcus', { read: MatPaginator, static: false }) topPaginatorcus : MatPaginator;
  @ViewChild('bottomPaginatorcus', { read: MatPaginator, static: false }) bottomPaginatorcus : MatPaginator;

  //Pagination Machine job
  lengthJob = 0;
  pageSizeJob  = 10;
  pageSizeOptionsJob : number[] = [10, 25, 50];
  pageIndexJob  = 0;
  offsetJob  = this.pageSizeJob  * this.pageIndexJob ;
  searchJob : string;
  sorteduJob  = {
    active: '',
    direction: ''
  };
  Job: ScheduleTracker[] = [];
  CloneJob: ScheduleTracker[] = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorJob : MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorJob : MatPaginator;

  PODB_controller: PODB_controller = new PODB_controller(this.db);
  machineDB_controller = new MachineDB_controller(this.db);

  machinelist = []
  role:string;
  acccessRight:any[]=[];
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private userinfoSrv:UserInfoService,

  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
    this.userinfoSrv.currentAccessRights.subscribe(access=>{
      this.acccessRight = access
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
  }
  disableDelete = true;

  check(PO: PurchaseOrder){
    const result = PO.PO_Part_List.find(p=>p.Added_TO_Packing===true)
    if(result ){
      return true;
    }
    else{
      return false;
    }
  }
  ngOnInit() {
    this.setup();

  }

  refresh(){
    this.setup();
    this.initcus();
  }

  setup(){
    this.spinner.show();
    this.initcus();
    this.machinelist = [];
    const db = this.db.database.ref('Machine/');
    db.once('value', (snapshot) => {
      snapshot.forEach(v => {
          this.machinelist.push(v.key);
      });
    });


    this.PODB_controller.getPOList().then(data => {
      this.POlist = data;
      this.POlist = this.POlist.filter(p=>p.PO_Part_List.length>=1);
      this.length = this.POlist.length;
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      this.spinner.hide();
    });
  }

  initcus(){
    this.CustomerDB_controller.getCustomerList().then(data => {
      this.Customer = data;
      this.lengthcus = this.Customer.length;
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      this.spinner.hide();
    });
  }

  initJob(){
    this.machineDB_controller.getMachineListForCompletedJob().then(data=>{
      this.Job = data;
      this.lengthJob = this.Job.length;
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      this.spinner.hide();
    })
  }

  deletePO(POnumber) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this PO? This will affect the schedule';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PODB_controller.delete_PO(POnumber, this.machinelist);
        this.setup();
      }
    })

  }


  DynamicSearchPO(partNum: string): void {
    this.search = partNum;
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No.toLowerCase().includes(this.search.toLowerCase()) || (part.PO_Part_Name.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
  }
  DS() {
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No.toLowerCase().includes(this.search.toLowerCase()) || (part.PO_Part_Name.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.ClonePOList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePOList = this.POlist.slice();
    }
    this.sortPOList();
    this.limitList();
  }
  limitList() {
    this.ClonePOList = this.ClonePOList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPOList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePOList = this.POlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      return;
    }

    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });
    this.limitList();
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ViewShippingInformation(part){
    this.dialog.open(ShippingInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }
  ViewCheckOut(part){
    this.dialog.open(RawCheckOutInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }



  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0);
  }


  viewOrder(po){
    console.log(po)
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  DynamicSearchRawcus(cusname: string): void {
    this.searchcus = cusname;
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
    this.sortRawListcus();
    this.limitListcus();
  }
  DScus() {
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
  }

  paginatorcus(pageEvent: PageEvent) {
    this.pageSizecus = pageEvent.pageSize;
    this.pageIndexcus = pageEvent.pageIndex;
    this.offsetcus = this.pageSizecus * this.pageIndexcus;
    if (this.topPaginatorcus.pageIndex < this.pageIndexcus) {
      this.topPaginatorcus.nextPage();
    } else if (this.topPaginatorcus.pageIndex > this.pageIndexcus) {
      this.topPaginatorcus.previousPage();
    }
    if (this.bottomPaginatorcus.pageIndex < this.pageIndexcus) {
      this.bottomPaginatorcus.nextPage();
    } else if (this.bottomPaginatorcus.pageIndex > this.pageIndexcus) {
      this.bottomPaginatorcus.previousPage();
    }
    if (this.searchcus) {
      this.DScus();
    } else {
      this.CloneCustomer = this.Customer.slice();
    }
    this.sortRawListcus();
    this.limitListcus();
  }
  limitListcus() {
    this.CloneCustomer = this.CloneCustomer.slice(this.offsetcus, (this.offsetcus + this.pageSizecus));
  }
  sortRawListcus() {
    if (!this.sorteducus.active || this.sorteducus.direction === '') {
      return;
    }
    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
  }
  sortDatacus(sort: Sort) {
    this.sorteducus = sort;
    this.CloneCustomer = this.Customer.slice();
    if (this.searchcus) {
      this.DScus();
    }
    if (!sort.active || sort.direction === '' && !this.searchcus) {
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      return;
    }

    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
         default: return 0;
      }
    });
    this.limitListcus();
  }

  ViewCustomer(Customer: Customer){

    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {...Customer}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.update_Customer(result, this.email);
        this.setup();
      }
    });
  }
  AddCustomer(){
    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.add_Customer(result, this.email);
        this.setup();
      }
    });
  }
  deletecus(cus : Customer){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this Customer? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.delete_Customer(cus.Customer_ID);
        this.setup();
      }
    })
  }

  AddPO(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddPOComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    })
  }

  viewDetail(PO){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = PO;
    this.dialog.open(DetailPOPageComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    })
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    for (const po of this.POlist) {
      const info ={
        "PO":po.PO_No || "-",
        "Part List": po.PO_Part_List.map(a=>a.PO_Part_No).join('  |  '),
        "Part List Name": po.PO_Part_List.map(a=>a.PO_Part_Name).join(' |  '),
        "Production Quantity": po.PO_Part_List.map(a=>a.PO_Part_Qty).join(' |  '),
        "PO Quantity": po.PO_Part_List.map(a=>a.POQuantity).join(' |  '),
        "Reference": po.PO_Part_List.map(a=>a.Reference).join(' |  '),
        "POS": po.PO_Part_List.map(a=>a.POS).join(' |  '),
        "Status": po.PO_Part_List.map(a=>a.PO_Status).join(' |  '),
        "Created Date":po.Created_Date
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelCustomer() {
    const exportInformation = [];
    this.spinner.show();
    const cuss = this.Customer.sort((a,b)=>{
      return (a.Customer_Name < b.Customer_Name ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const cus of cuss) {
      const info ={
        "Client name":cus.Customer_Name || "-",
        "Address":cus.Address || "-",
        "Contact":cus.Contact || "-",
        "Currency":cus.Currency || "-",
        "Email":cus.Email || "-",
        "Person In Charge":cus.Person_In_Charge || "-",
        "Created Date":cus.Created_Date || "-",
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }

  msToTime(end,start) {
    let duration = parseFloat(end)-parseFloat(start);
    if(duration > 0){
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  hoursDisplay(hr){
    if(hr > 0){
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60 ;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }

  DynamicSearchJob(job: string): void {
    this.searchJob = job;
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
      );
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
  }
  DSJob() {
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
      );
      this.lengthJob = this.CloneJob.length;

  }

  paginatorJob(pageEvent: PageEvent) {
    this.pageSizeJob = pageEvent.pageSize;
    this.pageIndexJob = pageEvent.pageIndex;
    this.offsetJob = this.pageSizeJob * this.pageIndexJob;
    if (this.topPaginatorJob.pageIndex < this.pageIndexJob) {
      this.topPaginatorJob.nextPage();
    } else if (this.topPaginatorJob.pageIndex > this.pageIndexJob) {
      this.topPaginatorJob.previousPage();
    }
    if (this.bottomPaginatorJob.pageIndex < this.pageIndexJob) {
      this.bottomPaginatorJob.nextPage();
    } else if (this.bottomPaginatorJob.pageIndex > this.pageIndexJob) {
      this.bottomPaginatorJob.previousPage();
    }
    if (this.searchJob) {
      this.DSJob();
    } else {
      this.CloneJob = this.Job.slice();
    }
    this.sortJob();
    this.limitListJob();
  }
  limitListJob() {
    this.CloneJob = this.CloneJob.slice(this.offsetJob, (this.offsetJob + this.pageSizeJob));
  }
  sortJob() {
    if (!this.sorteduJob.active || this.sorteduJob.direction === '') {
      return;
    }
    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd)-parseFloat(a.DatePresettingStart),parseFloat(b.DatePresettingEnd)-parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
        default: return 0;
      }
    });
  }
  sortDataJob(sort: Sort) {
    this.sorteduJob = sort;
    this.CloneJob = this.Job.slice();
    if (this.searchJob) {
      this.DScus();
    }
    if (!sort.active || sort.direction === '' && !this.searchcus) {
      this.CloneCustomer = this.Customer.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd)-parseFloat(a.DatePresettingStart),parseFloat(b.DatePresettingEnd)-parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
         default: return 0;
      }
    });
    this.limitListJob();
  }

  exportToExcelJob() {
    const exportInformation = [];
    this.spinner.show();
    const jo = this.Job.sort((a,b)=>{
      return (Number(a.Machine_Number.match(/(\d+)/g)[0]) - Number((b.Machine_Number.match(/(\d+)/g)[0])));})
    for (const j of jo) {
      const info ={
        "Purchase Order":j.Machine_Schedule_PO_No || "-",
        "Machine":j.Machine_Number || "-",
        "Part No":j.Machine_Schedule_Part_No || "-",
        "Part Name":j.PartName || "-",
        "Cycle Time":j.CycleTime || "-",
        "Start Time":j.DateFormatStart || "-",
        "End Time":j.DateFormatEnd || "-",
        "Presetting Time": this.msToTime(j.DatePresettingEnd,j.DatePresettingStart) || "-",
        "Downtime Time":this.hoursDisplay(j.TotalDownTime) || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }

  view(job:ScheduleTracker){
    this.dialog.open(DowntimeDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job.DownTimeDetail,
      disableClose:true
    });

  }

  viewTubing(job: ScheduleTracker) {
    this.dialog.open(JobTubingDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job,
      disableClose: true
    });
  }


  AddSchedule() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '95vw';

    const position = {
      top: '1%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddScheduleComponent, dialogConfig)
  }
}

