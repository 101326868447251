import { TubingDetailComponent } from './PopUpSchedule/details-schedule/tubing-detail/tubing-detail.component';
import { Tubing, TubingInfo } from './../../Services/Object_Classes/Tubing/Tubing';
import { ConfirmationDialogComponent } from './../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnChanges, NgZone, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MachineDB_controller } from '../../Services/DB_Controller/MachineDB_controller';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { WeightingDB_controller } from '../../Services/DB_Controller/WeightingDB_controller';
import { Machine, RawMaterialUsed, ScheduleTracker } from '../../Services/Object_Classes/Machine/Machine';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FinishDialogComponent } from 'src/app/Shared/finish-dialog/finish.component';
import { AlignDialogComponent } from 'src/app/Shared/align-dialog/align-dialog.component';
import { PinDialogComponent } from 'src/app/Shared/pin-dialog/pin-dialog.component';
import { DetailsScheduleComponent } from './PopUpSchedule/details-schedule/details-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})

export class ScheduleComponent implements OnInit {
  Machine_No: string;
  Schedule_Track: SchedulerComponent[];
  matSelectPart: Part[] = [];
  clone: Part[] = [];
  selectedPart: string[];
  manualTubingTimes: string[] = [];
  manualTubings: number[] = [];
  manualTubingQty: number;
  now: number;
  rejectTally: number;
  is: any;
  poNumber: any;
  scheduleNo: any;
  productionQuantity: any;
  subscribe: any;
  CodeSubmitted_flag: Boolean = false;
  Display_flag: Boolean = false;
  Button_flag: Boolean = false;
  AlertTimer: any;
  AlertCountDown: String;
  rejectMessage = "-";
  totalReject: Number = 0;
  totalTubing: number = 0;
  machinedb_Controller = new MachineDB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db, this.storage, this.firestore);
  weighting_Controller = new WeightingDB_controller(this.db);
  completion: number = 0.00;
  flaskServertimerInterval: any;
  DatetimerInterval: any;
  ViewUpcoming = false;
  iss: any;
  machineFailureStatus = true;
  Presetting_flag: Boolean = false;
  Machine: Machine = new Machine();
  personInCharge: string;
  photo: string = '';
  mould: string = "";
  autoTubing: number = 0;
  autoTubingMessage: string = "";
  numberOfTubings: number = 0;
  autoTubingInfos: TubingInfo[] = [];
  Category = "SELECT ONE";
  Categories = ["TUBING","MAN","EQUIPMENT"]
  InterruptCodeListShowing = [];

  InterruptCodeList = [
    {
      value: 'FB',
      ViewValue: '"Pop" Film Broke',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'WM',
      ViewValue: 'Water Marking',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'FS',
      ViewValue: 'FILM SIZE',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'CD',
      ViewValue: 'Colour Different',
      Color: '#ffcc99',
      Category:"TUBING"
    },

    {
      value: 'TO',
      ViewValue: 'Tubing Others',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'NMP',
      ViewValue: 'No Man Power',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'MO',
      ViewValue: 'Machine Off',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'MAN_OTH',
      ViewValue: 'Man Others',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'COF',
      ViewValue: 'Change of Filter',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'CB',
      ViewValue: 'Clean Balancer',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'DP',
      ViewValue: 'DPIO Problem',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'EO',
      ViewValue: 'Equipment Others',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
  ]

  rejectCodeList = [
    {
      value: 'SINK MARK',
      ViewValue: 'SINK MARK'
    },
    {
      value: 'SHORT MOULDING',
      ViewValue: 'SHORT MOULDING'
    },
    {
      value: 'FLASHING',
      ViewValue: 'FLASHING'
    },
    {
      value: 'OVER CUT',
      ViewValue: 'OVER CUT'
    },
    {
      value: 'COLOR OUT',
      ViewValue: 'COLOR OUT'
    },
    {
      value: 'WARPING',
      ViewValue: 'WARPING'
    },
    {
      value: 'MOISTURE',
      ViewValue: 'MOISTURE'
    },
    {
      value: 'DENTED',
      ViewValue: 'DENTED'
    },
    {
      value: 'BLACK DOT',
      ViewValue: 'BLACK DOT'
    },
    {
      value: 'SCRATCHES',
      ViewValue: 'SCRATCHES'
    },
    {
      value: 'WHITE DOT',
      ViewValue: 'WHITE DOT'
    },
    {
      value: 'OTHER',
      ViewValue: 'OTHER'
    }
  ];

  code: any;
  elem;
  email: any;

  constructor(
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private zone: NgZone,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private dateFormat: DateFormatService,
    private firestore: AngularFirestore,
    @Inject(DOCUMENT) private document: any
  ) {
    spinner.show();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.getFlaskServerStatus();
    this.flaskServertimerInterval = setInterval(() => {
      //this.getMachineFailureStatus();
      this.getFlaskServerStatus();
    }, 5000)
    this.DatetimerInterval = setInterval(() => {
      this.now = Date.now();
    }, 1000)

    this.subscribe = this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.subscribe.unsubscribe();
      } else {
        this.subscribe.unsubscribe();
        const snapshot = await this.db.database.ref('Operators').child(authState.uid).once('value');

        if (snapshot.exists()) {
          this.Machine_No = snapshot.child('machineAssigned').val();
          this.db.database.ref('Machine').child(this.Machine_No).on('value', async (DataSnapshot) => {
            this.getMachineInfo(DataSnapshot);
          })
        }
      }
    });
  }

  show() {
    this.ViewUpcoming = !this.ViewUpcoming;
  }

  quantityChange(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = "Align the Counter Value"

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = false;
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';

          const position = {top: '5%'};
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.data = 'Production Quantity is set to ' + result;

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              $('#productionQuantity').text(result);
              this.productionQuantity = result;
            }
          })
        }
        else {

        }
      }
    });
  }

  autoTubingTimes = [];
  getMachineInfo(DataSnapshot) {
    this.zone.run(async () => {
      this.Machine = this.machinedb_Controller.getSpecifyMachine(DataSnapshot);
      this.Machine.Schedule_Track = await this.getSortedScheduleList(this.Machine.Schedule_Track);
      if (this.Machine.Schedule_Track.length > 0) {
        if (this.Machine.Schedule_Track[0].DatePresettingStart == "-" &&
          this.Machine.Schedule_Track[0].DatePresettingEnd == "-" &&
          this.Machine.Schedule_Track[0].Machine_Schedule_Status == "Waiting") {
          this.Presetting_flag = true;
        }

        if(this.Machine.Schedule_Track[0].HourLeft < 0)
          this.Machine.Schedule_Track[0].HourLeft = 0;

        this.productionQuantity = this.Machine.Schedule_Track[0].Exp_Qty;
        this.weighting_Controller.getScheduleTubingInfo(this.Machine.Schedule_Track[0].Machine_Schedule_PO_No, this.Machine.Schedule_Track[0].Machine_Schedule_Part_No).then(data => {

          if(data && data.PONumber){
            if(this.manualTubingTimes.length === data.TubingInfos.length){
              this.Machine.Schedule_Track[0].Machine_Progress = isFinite(this.totalTubing/this.Machine.Schedule_Track[0].Exp_Qty)?(this.totalTubing/this.Machine.Schedule_Track[0].Exp_Qty*100):0;
              this.Machine.Schedule_Track[0].HourLeft = (this.Machine.Schedule_Track[0].Exp_Qty - this.totalTubing) / this.Machine.Schedule_Track[0].CycleTime;

              if(this.Machine.Schedule_Track[0].HourLeft < 0)
                this.Machine.Schedule_Track[0].HourLeft = 0;

              return;
            }
            var t = 0.00;
            this.rejectMessage = "";
            this.manualTubingTimes = [];
            this.manualTubings = [];


            data.TubingInfos.sort((a,b)=>{
              return new Date(a.TimeStamp).getTime() > new Date(b.TimeStamp).getTime()? -1 : new Date(a.TimeStamp).getTime() < new Date(b.TimeStamp).getTime() ? 1 :0
            })

            for (const element of data.TubingInfos.slice(0,3)) {
              this.rejectMessage += element.TimeStamp + " : " + element.TubingAmount.toFixed(2) + '\n';
            }

            data.TubingInfos.forEach(async (element, index) => {
              this.manualTubingTimes.push(element.TimeStamp);
              this.manualTubings.push(element.TubingAmount);
              t += parseFloat(element.TubingAmount.toString());
            });

            this.numberOfTubings = data.TubingInfos.length;
            this.Machine.Schedule_Track[0].Machine_Progress = isFinite(this.totalTubing/this.Machine.Schedule_Track[0].Exp_Qty)?(this.totalTubing/this.Machine.Schedule_Track[0].Exp_Qty*100):0;
            if(this.Machine.Schedule_Track[0].Machine_Progress > 100)
              this.Machine.Schedule_Track[0].Machine_Progress = 100
            this.totalTubing = t;

            if(data.TubingInfos.length > 0){
              this.Machine.Schedule_Track[0].HourLeft = (this.Machine.Schedule_Track[0].Exp_Qty - this.totalTubing) / parseFloat(this.Machine.Schedule_Track[0].PredefinedCycleTime);
              if(this.Machine.Schedule_Track[0].HourLeft < 0)
                this.Machine.Schedule_Track[0].HourLeft = 0;
            }
          }
        });

        this.weighting_Controller.getScheduleAutoTubingInfo(this.Machine.Schedule_Track[0].Machine_Schedule_PO_No, this.Machine.Schedule_Track[0].Machine_Schedule_Part_No).then(data => {
          if(data && data.PONumber){
            if(this.autoTubingTimes.length === data.TubingInfos.length)
              return;
            var t = 0;
            this.autoTubingMessage = "";
            console.log(this.autoTubingMessage)
            console.log(this.autoTubingTimes)
            this.autoTubingTimes = [];
            data.TubingInfos.sort((a,b)=>{
              return a.TimeStamp > b.TimeStamp? -1 : a.TimeStamp < b.TimeStamp ? 1 :0
            })

            for (const element of data.TubingInfos.slice(0,3)) {
              var timestamp = new Date(parseInt(element.TimeStamp));
              var month = timestamp.getMonth()+1;
              var year = timestamp.getFullYear();
              var day = timestamp.getDate();
              var hour = timestamp.getHours();
              var minutes = timestamp.getMinutes();
              var seconds = timestamp.getSeconds();
              var currentTimestamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
              this.autoTubingMessage += currentTimestamp + " : " + parseFloat((element.TubingAmount).toString()).toFixed(2) + '\n';
            }
            data.TubingInfos.forEach(async (element, index) => {
              t += parseFloat(element.TubingAmount.toString());
              this.autoTubingTimes.push(element);
            });


            this.autoTubingInfos = data.TubingInfos;
            this.autoTubing = t;
          }
        });
      }

      let Stopped_Schedule = this.getStoppedSchedule(this.Machine.Schedule_Track);

      if (Stopped_Schedule != null && this.Machine.Machine_Code.match('-')) {
        //this.Display_flag = true;
        this.getFlaskServerStatus();
        for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
          this.Machine.Schedule_Track[i].Active_Schedule_Status = false;
        }
      }
    });

    //this.machinedb_Controller.getUnsolveMachineStatus(this.Machine.Machine_No).then(flag =>{})
  }

  getFlaskServerStatus() {
    this.httpClient.post('http://127.0.0.1:5002/handshaking', { responseType: 'text' }).subscribe(
      response => {
        var splitted_response = response.toString().split("@@@");
        //False = machine without failure
        //True = machine with failure
        var machineFailureStatus = (splitted_response[1] == "true")

        if (this.Machine) {
          let Stopped_Schedule = this.getStoppedSchedule(this.Machine.Schedule_Track)
          let InProgress_Schedule = this.getInProgressSchedule(this.Machine.Schedule_Track)

          if (splitted_response[0].match("Thread is stopped") || splitted_response[0].match("Thread is not exist")) {
            if (InProgress_Schedule != null) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.Machine.MachineStatus,
                code: this.Machine.Machine_Code,
                po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
                scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
                action: "Update Schedule Status to Stop due to thread is stopped or not exist"
              }

              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
              this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + InProgress_Schedule.Machine_Schedule_No).update({ 'Schedule Status': 'Stopped' });
            }

            if (Stopped_Schedule != null) {
              if (this.Machine && this.Machine.Machine_Code.match('-')) {
                this.showToasterWarning();
                this.Display_flag = true;

                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              }
              else if (this.Machine && !machineFailureStatus) {
                this.Machine.Schedule_Track[0].Active_Schedule_Status = true;
                this.showToasterSuccess("The server is ready to start the task");
                this.Display_flag = false;
              }
              else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              }
            }
            else {
              let Waiting_Schedule = this.getWaitingSchedule(this.Machine.Schedule_Track)

              if (Waiting_Schedule != null && !machineFailureStatus) {
                this.Machine.Schedule_Track[0].Active_Schedule_Status = true;
              }
              else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                if (Waiting_Schedule != null) {
                  this.Machine.Schedule_Track[0].Active_Schedule_Status = false;
                }
              }
            }

          }
          else if (splitted_response[0].match("Thread is running")) {
            if (Stopped_Schedule != null) {
              if (this.Machine && this.Machine.Machine_Code.match('-')) {
                this.showToasterWarning();
                this.Display_flag = true;

                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              } else {
                this.Display_flag = false;
              }
            } else {
              this.Display_flag = false;
            }
          }
          this.zone.run(async () => { this.Machine, this.spinner.hide(); })
        }
      },
      error => {
        this.zone.run(async () => { this.spinner.hide(); })
        this.showToasterError("Ensure the flask server is connected");
        this.Display_flag = false;

        if (this.Machine) {
          let InProgress_Schedule = this.getInProgressSchedule(this.Machine.Schedule_Track)
          if (InProgress_Schedule != null) {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const log = {
              date: new Date(),
              status: this.Machine.MachineStatus,
              code: this.Machine.Machine_Code,
              po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
              scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
              action: "Update Schedule Status to Stop due to flask server is not connected"
            }
            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);

            this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + InProgress_Schedule.Machine_Schedule_No).update({ 'Schedule Status': 'Stopped' });
          }

          for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
            this.Machine.Schedule_Track[i].Active_Schedule_Status = false
          }
        }
      }
    );
  }

  showToasterSuccess(message: string) {
    this.toast.success(message, "", { timeOut: 2000, positionClass: 'toast-top-left' });
  }

  showToasterError(message: string) {
    this.toast.toastrConfig
    this.toast.error(message, "Error!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showRestartToasterWarning(message: string) {
    this.toast.toastrConfig
    this.toast.warning(message, "Warning!", { timeOut: 15000, positionClass: 'toast-top-center' });
  }

  showToasterWarning() {
    this.toast.warning("Enter the interrupt code for the machine stopped", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showToasterMachineWarning() {
    this.toast.warning("The machine failure is not resolved.", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  getMachineFailureStatus() {
    this.httpClient.post('http://127.0.0.1:5002/machineFailureStatus', { responseType: 'text' }).subscribe(
      response => {
        if (response.toString().match("true")) {
          this.machineFailureStatus = true
        } else {
          this.machineFailureStatus = false
        }
      },
      error => {
        this.machineFailureStatus = true
      }
    )
  }

  SelectCat(cat){
    this.Category = cat;
    this.InterruptCodeListShowing = this.InterruptCodeList.filter(x=>x.Category === cat);
  }

  addManualTubing(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '500px';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = "Add Manual Tubing"

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {
          this.manualTubingQty = result;
          var totalManualTubing = 0;
          var month = new Date().getMonth()+1;
          var year = new Date().getFullYear();
          var day = new Date().getDate();
          var hour = new Date().getHours();
          var minutes = new Date().getMinutes();
          var seconds = new Date().getSeconds();
          var currentTimestamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
          this.rejectMessage = "";

          if(this.manualTubingTimes.includes(currentTimestamp)){
            var temp = this.manualTubings[this.manualTubingTimes.indexOf(currentTimestamp)];
            var newValue = parseInt(temp.toString()) + parseInt(this.manualTubingQty.toString());
            this.manualTubings[this.manualTubingTimes.indexOf(currentTimestamp)] = newValue;
          }
          else{
            this.manualTubingTimes.unshift(currentTimestamp);
            this.manualTubings.unshift(this.manualTubingQty);
          }
          let tubing= {};
          tubing[currentTimestamp] = this.manualTubingQty

          for(var i=0; i<this.manualTubingTimes.length; i++){
            totalManualTubing += parseFloat(this.manualTubings[i].toString());
            if(i < 3)
              this.rejectMessage += this.manualTubingTimes[i] + " : " + parseFloat(this.manualTubings[i].toString()).toFixed(2) + '\n';
          }

          this.totalTubing = totalManualTubing;
          this.numberOfTubings = this.manualTubingTimes.length;

          this.weighting_Controller.getScheduleTubingInfo(this.Machine.Schedule_Track[0].Machine_Schedule_PO_No, this.Machine.Schedule_Track[0].Machine_Schedule_Part_No).then(data => {
            if(data && data.PONumber){
              if(data.TubingInfos.length > 0){
                this.db.database.ref('ManualTubing/' + this.Machine.Schedule_Track[0].Machine_Schedule_PO_No + '/' + this.Machine.Schedule_Track[0].Machine_Schedule_Part_No + '/Tubings').update(tubing);
              }
              else{
                this.db.database.ref('ManualTubing/' + this.Machine.Schedule_Track[0].Machine_Schedule_PO_No + '/' + this.Machine.Schedule_Track[0].Machine_Schedule_Part_No + '/Tubings').set(tubing);
              }
              this.db.database.ref('ManualTubing/' + this.Machine.Schedule_Track[0].Machine_Schedule_PO_No + '/' + this.Machine.Schedule_Track[0].Machine_Schedule_Part_No + '/UpdatedDate').set(new Date());

            }
            else{
              this.toast.error("Please contact admin, Update failed");
            }
          });
        }
        else {

        }
      }
    });
  }

  submitRejectCode(code, Schedule: ScheduleTracker) {
    var numberOfTubingsed = 0;
    this.rejectMessage = "";

    if(this.manualTubingTimes.includes(code)){
      var temp = this.manualTubings[this.manualTubingTimes.indexOf(code)];
      var newValue = parseInt(temp.toString()) + parseInt(this.manualTubingQty.toString());
      this.manualTubings[this.manualTubingTimes.indexOf(code)] = newValue;
    }
    else{
      this.manualTubingTimes.unshift(code);
      this.manualTubings.unshift(this.manualTubingQty);
    }

    let rejected= {};
    rejected[code] = this.manualTubingQty

    for(var i=0; i<this.manualTubingTimes.length; i++){
      numberOfTubingsed += parseFloat(this.manualTubings[i].toString());
      if(i < 3)
        this.rejectMessage += this.manualTubingTimes[i] + " : " + parseFloat(this.manualTubings[i].toString()).toFixed(2) + '\n';
    }

    this.totalTubing = numberOfTubingsed;
    this.numberOfTubings = this.manualTubingTimes.length;

    this.weighting_Controller.getScheduleTubingInfo(Schedule.Machine_Schedule_PO_No, Schedule.Machine_Schedule_Part_No).then(data => {
      if(data && data.PONumber){
        if(data.TubingInfos.length > 0){
          this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No + '/Rejected Part').update(rejected);
        }
        else{
          this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No + '/Rejected Part').set(rejected);
        }
        this.db.database.ref('ManualTubing/' + this.Machine.Schedule_Track[0].Machine_Schedule_PO_No + '/' + this.Machine.Schedule_Track[0].Machine_Schedule_Part_No + '/UpdatedDate').set(new Date());

      }
      else{
        this.toast.error("Please contact admin, Update failed");
      }
    });

    this.Presetting_flag = false;
    //$('#rejectCode').text(message);
  }
  


  setCounterTally() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = "Align the Counter Value "

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {
          const dialogConfig = new MatDialogConfig();

          dialogConfig.autoFocus = false;
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';
          const position = {
            top: '5%'
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.data = 'Align the actual quantity to ' + result + '.\nThe changes will be reflected in the next production cycle.';
          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.Machine.MachineStatus,
                code: this.Machine.Machine_Code,
                po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
                scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
                action: "Tally Quantity"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
              this.httpClient.post('http://127.0.0.1:5002/tally', (result), { responseType: 'text' }).subscribe(
                data => { this.showToasterSuccess("The value will be updated on the next production cycle") },
                error => { }
              )
            }
          })
        }
        else {

        }
      }
    });
  }

  enterPinToFinishSchedule(machineNumber, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(PinDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        if (Number(result)) {
          var snapshot = await this.db.database.ref('Passcode').once('value');
          var isValidPin = false;
          if (snapshot.exists()) {
            if (result == snapshot.child('Passcode1').val()) {
              this.personInCharge = snapshot.child('Person1').val();
              isValidPin = true;
            } else if (result == snapshot.child('Passcode2').val()) {
              this.personInCharge = snapshot.child('Person2').val();
              isValidPin = true;
            } else if (result == snapshot.child('Passcode3').val()) {
              this.personInCharge = snapshot.child('Person3').val();
              isValidPin = true;
            } else if (result == snapshot.child('Passcode4').val()) {
              this.personInCharge = snapshot.child('Person4').val();
              isValidPin = true;
            }

            if (isValidPin) {
              this.showToasterSuccess("Identity validated! Proceed to further action!");
              this.ScheduleFinishDialog(machineNumber, Schedule);
            } else {
              this.showToasterError("Invalid PIN number");
            }
          }
        } else {

        }
      }
    })
  }

  ScheduleFinishDialog(machineNumber, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    if (Schedule.Acc_Qty < Schedule.Exp_Qty) {
      dialogConfig.data = 'The production quantity (' + Schedule.Acc_Qty + ') is less than the PO required quantity!';
    } else {
      dialogConfig.data = 'The production quantity is ' + Schedule.Acc_Qty;
    }

    this.dialog.open(FinishDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.partdb_Controller.search_Part(Schedule.Machine_Schedule_Part_No).then(data => {
          Schedule.PartCavityNum = String(data.Number_Cavity);
          Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim();
          Schedule.PredefinedCycleTime = String(data.ExtrusionCycleTime);
          let startDate = new Date(Schedule.DateFormatStart);
          let endDate = new Date();
          let presetStartDate = new Date(Schedule.DatePresettingStart);
          let presetEndDate = new Date(Schedule.DatePresettingEnd);
          let totalRunTime = Math.abs(endDate.getTime() - startDate.getTime()) / 3600000;
          let totalPresetTime = (Math.abs(presetEndDate.getTime() - presetStartDate.getTime())) / 3600000;
          let totalBreakTime = Schedule.TotalDownTime;
          Schedule.Availability = (totalRunTime - totalPresetTime - totalBreakTime )/totalRunTime;
          Schedule.Performance = (Schedule.Acc_Qty * (data.Cycle_Time / 3600)) / totalRunTime;
          const addPOModel = {
            po: Schedule.Machine_Schedule_PO_No,
            machineChosen: machineNumber,
            scheduleID: Schedule.Machine_Schedule_No,
            scheduleID2: Schedule.Machine_Schedule_No2,
            scheduleID3: Schedule.Machine_Schedule_No3,
            availability: Number.isFinite(Schedule.Availability) ? Schedule.Availability : 0,
            performance: Number.isFinite(Schedule.Performance) ? Schedule.Performance : 0,
            Acc_Qty: Schedule.Acc_Qty,
            pic: this.personInCharge,
          };

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: addPOModel.machineChosen + ' Job End by <b>' + this.email + '</b>\n' + Schedule.Machine_Schedule_PO_No,
            date: new Date(),
            addPOModel
          };

          this.manualTubings = [];
          this.manualTubingTimes = [];
          this.totalTubing = 0;
          this.rejectMessage = "";
          this.autoTubing = 0;
          this.autoTubingMessage = "";
          this.autoTubingInfos = [];
          this.numberOfTubings = 0;
          this.scheduleNo = Schedule.Machine_Schedule_No;

          this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

          this.httpClient.post('http://127.0.0.1:5002/endSchedule', (Schedule), { responseType: 'text' }).subscribe(
            response => {
              this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF" });
              //this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF" });
              this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + this.scheduleNo).update({ "Schedule Status": "Done" });
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: 'OFF',
                code: this.Machine.Machine_Code,
                po: Schedule.Machine_Schedule_PO_No,
                scheduleNo: Schedule.Machine_Schedule_No,
                action: "End Schedule"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);

              if (response.toString().match("Production completed")) {
                this.showToasterSuccess("Scheduled task completed!");
                this.machinedb_Controller.updateJobOEE(addPOModel);
              }
            },
            error => {
              this.showToasterError("The scheduled task unable to finish due to the flask server is not connected.");
            }
          );
        })
      }
    })
  }

  ngOnInit() {
    this.elem = document.getElementById('full');
  }

  ngAfterViewInit(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }

  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    clearInterval(this.DatetimerInterval);
    clearInterval(this.flaskServertimerInterval);
  }

  restartWebApp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Close and restart the current web application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.httpClient.post('http://127.0.0.1:5002/RestartWebApp', { responseType: 'text' }).subscribe(
          data => {
            this.showToasterSuccess("Restarting the web application!")
          },
          error => {
            this.showRestartToasterWarning("Unable to locate the flask server, please the restart server manually! \n **(Close the web app -> click the ClickMe.sh)**");
          }
        );
      }
    })
  }

  startProcess(Schedule : ScheduleTracker) {
    this.Button_flag = true;
    Schedule.Button_Status = true;
    Schedule.Active_Schedule_Status = false;

    this.partdb_Controller.search_Part(Schedule.Machine_Schedule_Part_No).then(data =>{
      Schedule.PartCavityNum = String(data.Number_Cavity);
      Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim();
      Schedule.PredefinedCycleTime = String(data.Cycle_Time);
      Schedule.Counter_Per_Kg = String(parseFloat(data.Weight_Per_Kg)/parseFloat(data.Counter_Per_Kg.toString()));
      //Schedule.Weight_Per_Kg = String(data.Weight_Per_Kg);

      if(Schedule.Counter != null){
        Schedule.Action = "restart";

        if(Schedule.Acc_Qty === 0){
          const d ={
            startAt: new Date(),
            endAt: new Date(new Date().getTime() + (Schedule.DateFormatEnd.getTime() - Schedule.DateFormatStart.getTime()))
          }

          this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + Schedule.Machine_Schedule_No ).update(d);
        }

        this.httpClient.post('http://127.0.0.1:5002/',(Schedule),{responseType: 'text'}).subscribe(
          data => {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Machine " + this.Machine_No + ' started by <b>' + this.email + '</b>\n' ,
              date: new Date(),
            };
            const status ={
              Status: "In Progress",
            }

            this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + Schedule.Machine_Schedule_No ).update(status);
            this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
            this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);
            this.db.database.ref('Machine/'+ this.Machine_No).update({"Machine Status": "ON"});

            const log={
              date: new Date(),
              status: 'ON',
              code: this.Machine.Machine_Code,
              po:Schedule.Machine_Schedule_PO_No,
              scheduleNo:Schedule.Machine_Schedule_No,
              action: "Start Process"
            }
            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
            this.machinedb_Controller.Machine_restoreLogState(this.Machine_No,Schedule.Machine_Schedule_No)
            this.Button_flag = false
          },
          error => {
            this.Button_flag = false
          }
        )
      }
      else{
        Schedule.Action = "start";

        let newTubingRecord= {};
        newTubingRecord['CreatedDate'] = new Date().toISOString();
        newTubingRecord['UpdateBy'] = this.email;
        newTubingRecord['UpdatedDate'] = new Date().toISOString();
        newTubingRecord['ProductionQty'] = Schedule.Exp_Qty;

        let qcRecord= {};
        qcRecord['QC Date'] = new Date().toISOString();
        qcRecord['Update_by'] = this.email;
        qcRecord['Accepted Qty'] = Schedule.Exp_Qty;

        this.db.database.ref('ManualTubing/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No).set(newTubingRecord);
        this.db.database.ref('AutoTubing/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No).set(newTubingRecord);
        this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No).set(qcRecord);

        if(Schedule.Acc_Qty === 0){
          const d ={
            startAt: new Date(),
            endAt: new Date(new Date().getTime() + (Schedule.DateFormatEnd.getTime() - Schedule.DateFormatStart.getTime()))
          }

          this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + Schedule.Machine_Schedule_No ).update(d);
        }

        this.httpClient.post('http://127.0.0.1:5002/',(Schedule),{responseType: 'text'}).subscribe(
          data => {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
            const info = {
              message: "Machine " + this.Machine_No + ' started by <b>' + this.email + '</b>\n' ,
              date: new Date(),
            };
            const status ={
              Status: "In Progress",
            }

            this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + Schedule.Machine_Schedule_No ).update(status);
            this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
            this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);
            this.db.database.ref('Machine/'+ this.Machine_No).update({"Machine Status": "ON"});

            const log={
              date: new Date(),
              status: 'ON',
              code: this.Machine.Machine_Code,
              po:Schedule.Machine_Schedule_PO_No,
              scheduleNo:Schedule.Machine_Schedule_No,
              action: "Start Process"
            }
            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
            this.machinedb_Controller.Machine_restoreLogState(this.Machine_No,Schedule.Machine_Schedule_No)
            this.Button_flag = false
          },
          error => {
            this.Button_flag = false
          }
        )
      }
    })
  }

  async getPart(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(schedule.Machine_Schedule_Part_No).once('value');
    if (snapshot.exists()) {
      this.mould = snapshot.child('Mould/Mould Referance No').val();
      schedule.Counter_Per_Kg = snapshot.child('Counter Per Tubing').val();
      schedule.Weight_Per_Kg = snapshot.child('Weight Per Tubing').val();

      if (this.photo !== snapshot.child('PhotoURL').val()) {
        this.photo = snapshot.child('PhotoURL').val();
      }
    }
  }

  async getRawMaterialUsed(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').
      child(schedule.Machine_Schedule_PO_No + '/Part List/' + schedule.Machine_Schedule_No + '/Raw Materials Used').once('value');
    if (snapshot.exists()) {
      schedule.RawMaterialUsed = [];
      snapshot.forEach(element => {
        element.forEach(s => {
          console.log(s.val());
          const index = schedule.RawMaterialUsed.findIndex(u => u.MaterialName === s.child('RawMaterialName').val());
          if (index !== -1) {
            schedule.RawMaterialUsed[index].Quantity += parseInt(s.child('Quantity Checkout').val());
          } else {
            const rawMaterialUsed = new RawMaterialUsed;
            rawMaterialUsed.Quantity = s.child('Quantity Checkout').val();
            rawMaterialUsed.MaterialName = s.child('RawMaterialName').val();
            schedule.RawMaterialUsed.push(rawMaterialUsed);
          }
        })
      })
    }
  }

  async getSortedScheduleList(Schdule_TrackList: ScheduleTracker[]): Promise<ScheduleTracker[]> {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }

    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList);

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList);

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false;
      SortedScheduleList.unshift(InProgress_Schedule);
    }
    else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true;
      SortedScheduleList.unshift(Stopped_Schedule);
    }
    else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true;
    }

    SortedScheduleList.forEach(async e => {
      await this.getRawMaterialUsed(e);
    })

    if (SortedScheduleList.length > 0) {
      await this.getPart(SortedScheduleList[0]);
    }

    return SortedScheduleList;
  }

  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }

      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let InProgress_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {
        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }

    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  submitCode(code, Schedule: ScheduleTracker,viewValue) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Submit this interrupt code (' + viewValue + ')?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.getFlaskServerStatus();
        this.db.database.ref('Machine/' + this.Machine_No).update({ Code: code });
        this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF" });

        const status = {
          Status: "Stopped",
        }
        this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No).update(status);
        if (Schedule.Machine_Schedule_No2)
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No2).update(status);
        if (Schedule.Machine_Schedule_No3)
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No3).update(status);

        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: "Machine " + this.Machine_No + ' Submit code by <b>' + this.email + '</b>\n',
          date: new Date(),
        };

        this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

        const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
        const log = {
          date: new Date(),
          status: 'OFF',
          code: code,
          action: "Submit Interrupt Code",
          po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
          scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No
        }
        this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
        this.machinedb_Controller.setMachine_Logfile(this.Machine_No, this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No, code);
        this.CodeSubmitted_flag = true;
        this.Category = "SELECT ONE";
        this.InterruptCodeListShowing = [];
        // document.getElementById("overlay").style.display = "none";
      }
    })
  }

  /*
    submitCodeForRunningMachine(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = 'auto';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = 'Submit this interrupt code (' + this.code + ')?';
      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
        if(result){
          this.db.database.ref('Machine/'+ this.Machine_No).update({Code: this.code});
          //this.machinedb_Controller.setMachineStopped_Code(this.Machine_No,this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No,this.code);
          //clearInterval(this.AlertTimer);
        }
      })
    }

    restore(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = 'auto';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.data = 'Restore Back?';
      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
        if(result){
          this.Error_Message = "";
          this.CodeSubmitted_flag = true;
          this.ButtonEnable = true;
          this.db.database.ref('Machine/'+ this.Machine_No).update({Code: '-'});
          this.machinedb_Controller.restoreMachine_StoppedTime(this.Machine_No,this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No);
        }
      })
    }
  */
  stop() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };

    /*
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Stop the process?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.machineRunning = false;
        this.stopProcess()

        this.db.database.ref('Machine/'+ this.Machine_No + '/Schedule/' + this.getInProgressSchedule(this.Machine.Schedule_Track).Machine_Schedule_No).update({'Schedule Status': 'Stopped'});
        this.machinedb_Controller.saveMachine_StoppedTime(this.Machine_No,this.getInProgressSchedule(this.Machine.Schedule_Track).Machine_Schedule_No);

        this.AlertTimerFunc();
      }
    })
    */
    //this.machinedb_Controller.saveMachine_StoppedTime(this.Machine_No,this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No);
    this.AlertTimerFunc();
  }

  AlertTimerFunc() {
    let counter: number = 900 //seconds
    this.AlertTimer = setInterval(() => {
      if (counter > 0) {
        counter--;
        let m: number = Math.floor(counter / 60);
        let s: number = counter % 60;

        let minutes = m < 10 ? "0" + m : String(m);
        let seconds = s < 10 ? "0" + s : String(s);

        this.AlertCountDown = minutes + ":" + seconds;
      } else {
        clearInterval(this.AlertTimer);
      }
    }, 1000)
  }

  viewScheduleDetail(schedule): void {
    const dialogRefupdatePart = this.dialog.open(DetailsScheduleComponent, {
      data: schedule,
      width: '70%',
      height: '30%'
    });
  }

  startPresettingTime() {
    let startTime = Date.now();
    const addPOModel = {
      machineChosen: this.Machine.Machine_No,
      scheduleID: this.Machine.Schedule_Track[0].Machine_Schedule_No,
      startTimer: startTime,
    };
    this.machinedb_Controller.updatePresetStartTime(addPOModel);
    let element = <HTMLInputElement>document.getElementById("mouldSetEnd");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldSetStart");
    element1.disabled = true;
  }

  continueProcess(message: string, message1: string) {
    let element = <HTMLInputElement>document.getElementById(message);
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById(message1);
    element1.disabled = true;
  }

  endPresettingTime() {
    let endTime = Date.now();
    const addPOModel = {
      machineChosen: this.Machine.Machine_No,
      scheduleID: this.Machine.Schedule_Track[0].Machine_Schedule_No,
      endTimer: endTime,
    };
    this.machinedb_Controller.updatePresetEndTime(addPOModel);
    let element = <HTMLInputElement>document.getElementById("mouldTestEnd");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldTestStart");
    element1.disabled = true;
    this.Presetting_flag = false;
    this.resetPresetGUI();
  }

  resetPresetGUI() {
    let element = <HTMLInputElement>document.getElementById("mouldSetStart");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldSetEnd");
    element1.disabled = true;
    let element2 = <HTMLInputElement>document.getElementById("preheatStart");
    element2.disabled = true;
    let element3 = <HTMLInputElement>document.getElementById("preheatEnd");
    element3.disabled = true;
    let element4 = <HTMLInputElement>document.getElementById("mouldTestStart");
    element4.disabled = true;
    let element5 = <HTMLInputElement>document.getElementById("mouldTestEnd");
    element5.disabled = true;
  }

  viewDetail(type){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    let message = "";
    if(type === "Manual"){
      this.weighting_Controller.getScheduleTubingInfo(this.Machine.Schedule_Track[0].Machine_Schedule_PO_No, this.Machine.Schedule_Track[0].Machine_Schedule_Part_No).then(data => {

        if(data && data.PONumber){
          if(data.TubingInfos.length > 0){
            data.TubingInfos.sort((a,b)=>{
              return new Date(a.TimeStamp).getTime() > new Date(b.TimeStamp).getTime()? -1 : new Date(a.TimeStamp).getTime() < new Date(b.TimeStamp).getTime() ? 1 :0
            });

            for(var i=0; i<data.TubingInfos.length-1; i++){
              data.TubingInfos[i].TubingDifferent = new Date(data.TubingInfos[i].TimeStamp).getTime() - new Date(data.TubingInfos[i+1].TimeStamp).getTime();
              data.TubingInfos[i].TubingDifferent = data.TubingInfos[i].TubingDifferent / 3600000;
            }

            data.TubingInfos[data.TubingInfos.length-1].TubingDifferent = 0;

            data.TubingInfos.forEach(async (element, index) => {
              message += element.TimeStamp + " : " + element.TubingAmount + " (" + element.TubingDifferent.toFixed(2).toString() + " hours)" + '\n';
            });
          }

          let datas = {
            type:type,
            display: type==="Manual"? "Total Tubing Weight (kg)": "Total Weight (kg)",
            totalTubing: type==="Manual"?this.totalTubing: this.autoTubing,
            message: message
          }

          dialogConfig.data = datas;
          this.dialog.open(TubingDetailComponent, dialogConfig);
        }
      });
    }
    else{
      if(this.autoTubingInfos.length > 0){
        for(var i=0; i<this.autoTubingInfos.length-1; i++){
          this.autoTubingInfos[i].TubingDifferent = parseFloat(this.autoTubingInfos[i].TimeStamp) - parseFloat(this.autoTubingInfos[i+1].TimeStamp);
          this.autoTubingInfos[i].TubingDifferent = this.autoTubingInfos[i].TubingDifferent / 3600000;
        }

        this.autoTubingInfos[this.autoTubingInfos.length-1].TubingDifferent = 0;
      }

      for (const element of this.autoTubingInfos) {
        var timestamp = new Date(parseInt(element.TimeStamp));
        var month = timestamp.getMonth()+1;
        var year = timestamp.getFullYear();
        var day = timestamp.getDate();
        var hour = timestamp.getHours();
        var minutes = timestamp.getMinutes();
        var seconds = timestamp.getSeconds();
        var currentTimestamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
        message += currentTimestamp + " : " + element.TubingAmount + "  (" + element.TubingDifferent.toFixed(2).toString() + " hours)" + '\n';
      }

      let data = {
        type:type,
        display: type==="Manual"? "Total Tubing Weight (kg)": "Total Weight (kg)",
        totalTubing: type==="Manual"?this.totalTubing: this.autoTubing,
        message: message
      }

      dialogConfig.data = data;
      this.dialog.open(TubingDetailComponent, dialogConfig);
    }
  }
}

export class SchedulerComponent {
  private Machine_Schedule_No: string;
  private Exp_Qty: number;
  private Acc_Qty: number;
  private Machine_Schedule_PO_No: string;
  private Machine_Schedule_Part_No: string;
  private Machine_Schedule_Status: string;

  constructor() { }
}
