import { NgxSpinnerService } from 'ngx-spinner';
import { ViewRawMaterialsComponent } from './../../../Shared/view-raw-materials/view-raw-materials.component';
import { ConfirmationDialogComponent } from './../../../Shared/confirmation-dialog/confirmation-dialog.component';
import { ScheduleComponent } from './../../schedule/schedule.component';
import { SelectMachineSlotComponent } from './select-machine-slot/select-machine-slot.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from '../../../Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from '../../../Services/Object_Classes/Part/Part';
import { ScheduleTracker, Machine } from '../../../Services/Object_Classes/Machine/Machine';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {
  poNo: any;
  lotNumber: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  partW: string;
  partL: string;
  partT: string;
  partG: string;
  part: Part = new Part();
  machine: Machine = new Machine();
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  po_controller: PODB_controller = new PODB_controller(this.db);
  machineList: Machine[] = [];
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  availableMachineList2: any[] = [];
  selectedMachineList2: any[] = [];
  partlist: Part[] = [];
  matSelectPart: Part[] = [];
  clone: Part[] = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  newPO: PurchaseOrder = new PurchaseOrder();
  newPO_PartList: PartTracker[] = [];
  startDate1 = [];
  endDate1 = [];
  startDate2 = [];
  endDate2 = [];
  email: string;
  formvalid = [];
  searchPart: any;

  check = false;
  forecastChecked = false;
  plantChecked = false;
  partitionChecked = false;
  existingName = false;
  blowingCheck = false;
  cuttingCheck = false;

  @ViewChild('picker', { static: true }) picker: any;

  public date: moment.Moment;
  public minDate: Date;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [{ value: true, label: 'True' }, { value: false, label: 'False' }];
  private dateFormat = new DateFormatService();

  constructor(
    private dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
  ) {
    this.addForm = this.fb.group({name: '', lotNumber: '', parts: this.fb.array([])});
    this.minDate = new Date();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
    this.addParts();
  }

  async nameValidation() {
    const name = this.addForm.get('name').value;
    var snapshot = await this.db.database.ref('Purchase Order/' + name).once('value');

    if (snapshot.exists()) {
      this.existingName = true;
    }
    else if (name.includes('/') || name.includes('@')) {
      this.existingName = true
    }
    else{
      this.existingName = false;
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.clone = this.matSelectPart.slice();
      this.spinner.hide();
    });
  }

  change() {
    if (this.forecastChecked) {
      this.addForm.get('name').disable();
      this.addForm.get('name').setValue('');
    }
    else {
      this.addForm.get('name').enable();
    }
  }

  plantAction() {
    if (this.plantChecked) {
      this.plantChecked = true;
      $('#blowingRow').hide();
      $('#blowing').prop('checked', false);
      $('#blowing').attr('disabled', 'true');
    }
    else {
      this.plantChecked = false;
      $('#blowingRow').show();
      $('#blowing').removeAttr('disabled');
    }
  }

  // partitionAction() {
  //   if (this.partitionChecked) {
  //     this.addForm.get('lotNumber').enable();
  //   }
  //   else {
  //     this.addForm.get('lotNumber').disable();
  //     this.addForm.get('lotNumber').setValue('');
  //   }
  // }

  showBlowing() {
    if($('#blowing').is(':checked') && !this.plantChecked) {
      this.blowingCheck = true;
      $('#blowingRow').show();
    }
    else {
      this.blowingCheck = false;
      $('#blowingRow').hide();
    }
  }

  showCutting() {
    if($('#cut').is(':checked')) {
      this.cuttingCheck = true;
      $('#cuttingRow').show();
    }
    else {
      this.cuttingCheck = false;
      $('#cuttingRow').hide();
    }
  }

  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }

  newParts(): FormGroup {
    const partControl = new FormControl();
    const partfilterControl = new FormControl();
    const index = this.parts().length;

    partfilterControl.valueChanges.subscribe((value) => {
      this.findPart(partfilterControl);
    });

    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        this.searchPart = partControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partControl,
      partfilterControl,
      partNumber: '',
      partDescription: '',
      partW: '',
      partL: '',
      partT: '',
      partG: '',
      material: '',
      colourBase: '',
      frontPrinting: '',
      backPrinting: '',
      quantity: '',
      availableStock: '',
      raw: '',
      completeTime: '',
      completeTime2: '',
      availableMachineList: '',
      availableMachineList2: '',
      dateControl: new Date(),
      poquantity: '',
      remark:'',
      flag:false,
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));
  }

  addParts() {
    this.parts().push(this.newParts());
    this.availableMachineList.push(null);
    this.selectedMachineList.push(null);
    this.availableMachineList2.push(null);
    this.selectedMachineList2.push(null);
    this.partlist.push(null);
    this.runningScheduleList.push(null);
    this.estimateTotalTime.push(null);
    this.startDate1.push(null);
    this.endDate1.push(null);
    this.startDate2.push(null);
    this.endDate2.push(null);
    this.formvalid.push(true);
    const info = {part: "", index: -1,}
    this.choosingPart.push(info);
  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.availableMachineList.splice(i, 1);
    this.availableMachineList2.splice(i, 1);
    this.partlist.splice(i, 1);
    this.runningScheduleList.splice(i, 1);
    this.estimateTotalTime.splice(i, 1);
    this.startDate1.splice(i, 1);
    this.endDate1.splice(i, 1);
    this.startDate2.splice(i, 1);
    this.endDate2.splice(i, 1);
    this.formvalid.splice(i, 1);
    this.choosingPart.splice(i,2);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if (this.existingName) {
      this.toast.error('Invalid character("/@") inside the Lot name ', 'Please change!');
      return;
    }

    if (this.dateFormat.checkCrushDate(this.startDate1, this.endDate1, this.selectedMachineList)) {
      this.toast.error('There a crush between new added part in blowing machine scheduling', 'Please check');
      return;
    };

    // if (this.dateFormat.checkCrushDate(this.startDate2, this.endDate2, this.selectedMachineList2)) {
    //   this.toast.error('There a crush between new added part in cutting machine scheduling', 'Please check');
    //   return;
    // };

    const addPOModel = {
      POName: this.addForm.get('name').value,
      LotNumber: this.addForm.get('lotNumber').value,
      parts: []
    };
    const parts = this.addForm.get('parts').value;
    let date, endDate, date2, endDate2;

    parts.forEach(async (element, index) => {
      if (!this.selectedMachineList[index] && this.blowingCheck && !this.plantChecked) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }

      if (!this.selectedMachineList2[index] && this.cuttingCheck) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }

      //if(this.blowingCheck){
        date = this.startDate1[index];
        endDate = this.endDate1[index];
      /*}

      if(this.cuttingCheck){
        date2 = this.startDate2[index];
        endDate2 = this.endDate2[index];
      }*/

      if (this.forecastChecked){
        element.poquantity = 0;
      }

      //if(this.blowingCheck && !this.plantChecked){
        const info = {
          machineChosen: this.selectedMachineList[index].Machine_Name,
          machineNumber: this.selectedMachineList[index].Machine_No,
          completeTime: this.estimateTotalTime[index],
          startTime: date,
          endTime: endDate,
          partNumber: element.partControl,
          partName: this.partlist[index].Part_Name,
          pos:element.pos,
          reference:element.reference,
          quantity: element.quantity,
          raw: this.partlist[index],
          scheduleID: uuidv4(),
          poquantity: element.poquantity,
          remark: element.remark
        };

        if(isNaN(parseFloat(element.poquantity))){
          this.toast.error('Part information(' + (index + 1) + ') PO quantity not completed!', 'Please fill in');
          return;
        }else if(parseFloat(element.poquantity) === 0 && !this.forecastChecked){
          this.toast.error('Part information(' + (index + 1) + ') PO quantity cant be 0', 'Please fill in');
          return;
        }

        if (!info.machineChosen || !info.completeTime || !info.poquantity
          || !info.partNumber || !info.partName || !info.quantity || !info.raw || !this.formvalid[index]) {
          this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
          return;
        }
        else{
          addPOModel.parts.push(info);
        }
      //}

      /*if(this.cuttingCheck){
        const info2 = {
          machineChosen: this.selectedMachineList2[index].Machine_No,
          completeTime: this.estimateTotalTime[index],
          startTime: date2,
          endTime: endDate2,
          partNumber: element.partControl,
          partName: this.partlist[index].Part_Name,
          pos:element.pos,
          reference:element.reference,
          quantity: element.quantity,
          raw: this.partlist[index],
          scheduleID: uuidv4(),
          poquantity: element.poquantity,
        };

        if (!info2.machineChosen || !info2.completeTime || !(info2.poquantity >= 0)
          || !info2.partNumber || !info2.partName || !info2.quantity || !info2.raw || !this.formvalid[index]) {
          this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
          return;
        }
        else{
          addPOModel.parts.push(info2);
        }
      }*/
    });

    if (addPOModel.parts.length === 0) {
      this.toast.error('Please add at least one part', 'Add part');
      return;
    }

    if (!addPOModel.POName && !this.forecastChecked ) {
      this.toast.error('Please fill in the Lot name', 'Please fill in');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this PO?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        if (!this.forecastChecked) {
          await this.po_controller.addPO(addPOModel, this.email);
          this.spinner.hide();

          this.dialogRef.close(addPOModel);
          this.toast.success('Add PO successful');
        }
        else {
          addPOModel.POName = this.getRandomString(5) + '-FORECAST';
          await this.po_controller.addPO(addPOModel, this.email);
          this.spinner.hide();

          this.dialogRef.close(addPOModel);
          this.toast.success('Add PO successful');
        }
      }
    });
  }

  getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  choosingPart = [];

  getMachineForPart(partNumber: any, formindex) {
    this.spinner.show();
    const samePart = this.choosingPart.find(c => c.part === partNumber && c.index !== formindex);

    if (samePart) {
      this.toast.warning("Same part number has been ordered, please choose others", "Warning");
      this.partlist[formindex] = null;
      this.availableMachineList[formindex]=null;
      this.availableMachineList2[formindex]=null;
      this.spinner.hide();
      return;
    }

    if(this.clone.find(p => p.Part_No === partNumber).Raw_Material != null){
      const rawMaterial = this.clone.find(p => p.Part_No === partNumber).Raw_Material.find(r=>r.Raw_Type === 'Raw Material');
    }

    const parts = this.parts().controls;

    if (partNumber) {
      this.part_controller.search_Part(partNumber).then(data => {
        this.partlist[formindex] = data;

        $('[name="partDescription"]').val(data.Part_Name);
        $('[name="partW"]').val(data.Part_Width + ' ' +  data.widthUnit.toLowerCase());
        $('[name="partL"]').val(data.Part_Length + ' ' +   data.lengthUnit.toLowerCase());
        $('[name="partT"]').val(data.Part_Thickness + ' ' +   data.thicknessUnit.toLowerCase());
        $('[name="partG"]').val(data.Part_Gusset + ' ' +   data.gussetUnit.toLowerCase());
        $('[name="material"]').val(data.Part_Material);
        $('[name="colourBase"]').val(data.Color);
        $('[name="frontPrinting"]').val(data.Front_Printing);
        $('[name="backPrinting"]').val(data.Back_Printing);

        $('[name="flip"]').val(data.Flip);
        $('[name="air"]').val(data.Air_Gaps);
        $('[name="hole"]').val(data.Holes);
        $('[name="cutting"]').val(data.Cutting);


        const info = {part: partNumber, index: formindex};
        this.choosingPart[formindex] = info;
        this.machine_controller.getMachineListWithLastSchedule().then(data => {
          this.machineList = data;
          this.machineList = this.machineList.sort((a,b)=>a.Machine_Name>b.Machine_Name?1:-1);
          const list = [];
          const blowingList = [];
          const cuttingList = [];
          //const maclist = this.partlist[formindex].Available_Machine.split(',');
          this.machineList.forEach(machine => {
            list.push(machine);
          });

          let previous, current, decide, previous2, current2, decide2;
          list.forEach(machine => {
            if (machine.Schedule_Track.length !== 0) {
              machine.startDate = new Date(machine.Schedule_Track[0].Machine_Schedule_Start_Date);
              machine.endDate = new Date(machine.Schedule_Track[0].Machine_Schedule_End_Date);

              if (machine.Machine_Type == "BLOWING") {
                if (!previous) {
                  previous = machine;
                  decide = previous;
                }
                else {
                  current = machine;

                  if (new Date(current.Schedule_Track[0].Machine_Schedule_End_Date).getTime() < new Date(previous.Schedule_Track[0].Machine_Schedule_End_Date).getTime()) {
                    decide = current;
                  }
                  else {
                    decide = previous;
                  }

                  previous = current;
                }
              }
              else if (machine.Machine_Type == "CUTTING") {
                if (!previous2) {
                  previous2 = machine;
                  decide2 = previous2;
                }
                else {
                  current2 = machine;

                  if (new Date(current2.Schedule_Track[0].Machine_Schedule_End_Date).getTime() < new Date(previous2.Schedule_Track[0].Machine_Schedule_End_Date).getTime()) {
                    decide2 = current2;
                  }
                  else {
                    decide2 = previous2;
                  }

                  previous2 = current2;
                }
              }
            }
          });

          // Seperate to Blowing and Cutting
          list.forEach(machine => {
            if (machine.Machine_Type == "BLOWING") {
              blowingList.push(machine);
            }
            else if (machine.Machine_Type == "CUTTING") {
              cuttingList.push(machine);
            }
          });

          this.availableMachineList[formindex] = blowingList;
          this.availableMachineList2[formindex] = cuttingList;

          if (decide) {
            this.selectedMachineList[formindex] = decide;
            this.startDate1[formindex] = new Date(decide.Schedule_Track[0].Machine_Schedule_End_Date);

            if(this.startDate1[formindex].getTime() < new Date().getTime()){
              this.startDate1[formindex] =new Date();
            }

            this.startDate1[formindex].setHours(this.startDate1[formindex].getHours() + 1);
          }
          else {
            this.startDate1[formindex] = new Date();
            this.startDate1[formindex].setHours(this.startDate1[formindex].getHours() + 1);
          }

          if (decide2) {
            this.selectedMachineList2[formindex] = decide2;
            this.startDate2[formindex] = new Date(decide2.Schedule_Track[0].Machine_Schedule_End_Date);

            if(this.startDate2[formindex].getTime() < new Date().getTime()){
              this.startDate2[formindex] =new Date();
            }

            this.startDate2[formindex].setHours(this.startDate2[formindex].getHours() + 1);
          }
          else {
            this.startDate2[formindex] = new Date();
            this.startDate2[formindex].setHours(this.startDate2[formindex].getHours() + 1);
          }
        });
      });
    }
    this.spinner.hide();
  }

  selectSlot(event, i) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = event.value;

    this.dialog.open(SelectMachineSlotComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.dateslots[i] = result.date;
        this.timeslots[i] = result.slot;
      }
    });
  }

  estimateTime(partQuantity: any, formindex) {
    this.estimateTotalTime[formindex] = partQuantity * (parseFloat(this.partlist[formindex].ExtrusionCycleTime)/3600);
    this.endDate1[formindex] = new Date(this.startDate1[formindex].getTime() + this.estimateTotalTime[formindex] * 60 * 60000);
    this.endDate2[formindex] = new Date(this.startDate2[formindex].getTime() + this.estimateTotalTime[formindex] * 60 * 60000);

    var mac = null;
    this.db.database.ref('Machine').child(this.selectedMachineList[formindex].Machine_No).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.startDate1[formindex], this.endDate1[formindex], mac)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid[formindex] = false;
          return;
        }
        else {
          this.formvalid[formindex] = true;
        }

        // if (this.dateFormat.validateSchedulingMachine(this.startDate2[formindex], this.endDate2[formindex], mac)) {
        //   this.toast.error('Crush with original schedule', 'Please select other time');
        //   this.formvalid[formindex] = false;
        //   return;
        // }
        // else {
        //   this.formvalid[formindex] = true;
        // }
      }
    })
    if (this.addForm.get('parts').value[formindex].quantity) {
      this.viewRaw(this.partlist[formindex].Raw_Material, formindex)
    }
  }


  selectMachine(formindex, machineindex) {
    this.selectedMachineList[formindex] = this.availableMachineList[formindex][machineindex];
    let endDate = new Date(this.selectedMachineList[formindex].Schedule_Track[0].Machine_Schedule_End_Date);
    this.startDate1[formindex] = new Date(this.selectedMachineList[formindex].Schedule_Track[0].Machine_Schedule_End_Date);

    if(endDate.getTime() < new Date().getTime()){
      endDate = new Date();
      this.startDate1[formindex] =new Date();
    }
    endDate.setHours(endDate.getHours() +1);
    this.startDate1[formindex].setHours(this.startDate1[formindex].getHours() + 1);

    if(this.estimateTotalTime[formindex]){
      this.endDate1[formindex] = new Date( endDate.getTime() + this.estimateTotalTime[formindex] * 60 * 60000);
    }
  }

  selectCuttingMachine(formindex, machineindex) {
    this.selectedMachineList2[formindex] = this.availableMachineList2[formindex][machineindex];
    let endDate = new Date(this.selectedMachineList2[formindex].Schedule_Track[0].Machine_Schedule_End_Date);
    this.startDate2[formindex] = new Date(this.selectedMachineList2[formindex].Schedule_Track[0].Machine_Schedule_End_Date);

    if(endDate.getTime() < new Date().getTime()){
      endDate = new Date();
      this.startDate2[formindex] =new Date();
    }
    endDate.setHours(endDate.getHours() +1);
    this.startDate2[formindex].setHours(this.startDate2[formindex].getHours() + 1);

    if(this.estimateTotalTime[formindex]){
      this.endDate2[formindex] = new Date(endDate.getTime() + this.estimateTotalTime[formindex] * 60 * 60000);
    }
  }

  ChangeDate(formindex) {
    if(this.endDate1 == null || this.endDate1[formindex] == null){
      this.endDate1[formindex] = new Date(this.startDate1[formindex].getTime());
    }

    this.endDate1[formindex] = new Date(this.startDate1[formindex].getTime() + this.estimateTotalTime[formindex] * 60 * 60000);
    var mac = null;

    this.db.database.ref('Machine').child(this.selectedMachineList[formindex].Machine_No).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);

      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.startDate1[formindex], this.endDate1[formindex], mac)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid[formindex] = false;
          return;
        }
        else {
          this.formvalid[formindex] = true;
        }
      }
    })
  }

  ChangeDate2(formindex) {
    if(this.endDate2 == null || this.endDate2[formindex] == null){
      this.endDate2.splice(formindex, 0, null);
    }

    this.endDate2[formindex] = new Date(this.startDate2[formindex].getTime() + this.estimateTotalTime[formindex] * 60 * 60000);
    var mac = null;

    this.db.database.ref('Machine').child(this.selectedMachineList[formindex].Machine_No).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);

      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.startDate2[formindex], this.endDate2[formindex], mac)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid[formindex] = false;
          return;
        }
        else {
          this.formvalid[formindex] = true;
        }
      }
    })
  }

  viewRaw(materials, formindex, value = 0, part2 = null, part3 =null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {top: '5%'};
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    var quantity = this.addForm.get('parts').value[formindex].quantity;
    var partInfo = this.partlist[formindex];
    var multiple = value;

    dialogConfig.data = { materials, quantity, partInfo, multiple};

    this.dialog.open(ViewRawMaterialsComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result.Material) {
        materials.forEach((element, index) => {
          element.check = result.Material[index].check;
          element.quantityNeeded = result.Material[index].quantityNeeded;
        });
        /*this.partlist[formindex].innerCheck = result.innerCheck;
        this.partlist[formindex].polyCheck = result.polyCheck;
        this.partlist[formindex].polyBagNeeded = result.polyBagNeeded;
        this.partlist[formindex].cartonNeeded = result.cartonNeeded;
        this.partlist[formindex].innerBoxNeeded = result.innerBoxNeeded;*/
      }
    });
  }

  parFloat(value) {
    return parseFloat(value);
  }

  previewPrice(raw, formindex) {
    var total = 0;
    total += parseFloat(this.partlist[formindex].CartonBox.Unit_Price) * parseFloat(this.partlist[formindex].cartonNeeded);

    raw.forEach((element, index) => {
      if (element.check) {
        total += parseFloat(element.Unit_Price) * parseFloat(element.quantityNeeded);
      }

    });

    if (this.partlist[formindex].innerCheck) {
      total += parseFloat(this.partlist[formindex].InnerBox.Unit_Price) * parseFloat(this.partlist[formindex].innerBoxNeeded);
    }
    if (this.partlist[formindex].polyCheck) {
      total += parseFloat(this.partlist[formindex].PolyBag.Unit_Price) * parseFloat(this.partlist[formindex].polyBagNeeded);
    }
    if (isNaN(total)) {
      return 'None'
    }

    return "RM" + total.toFixed(2);

  }

  checkStock(value){
    const part = this.clone.find(p=>p.Part_No === value);
    if(part){
      this.toast.info("Stock quantity: " + part.Stock_Quantity,'Part No: ' +part.Part_No,{
        positionClass: 'toast-top-center'
     })
    }

  }
  Stock(value){
    if(value){
      const part = this.clone.find(p=>p.Part_No === value);
      return part.Stock_Quantity;
    }
    return ''
  }

  tooltips(value){
    const part = this.clone.find(p=>p.Part_No === value);
    if(part){
      return "Stock quantity: " + part.Stock_Quantity
    }
  }
}
