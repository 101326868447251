import { BoxInfo, RejectedInfo, TakenbyPO } from './../Object_Classes/Weighting/Weighting';
import { AngularFireDatabase } from '@angular/fire/database';
import { Weighting, WeightPartList } from '../Object_Classes/Weighting/Weighting';
import moment from 'moment';
import { Tubing, TubingInfo } from './../Object_Classes/Tubing/Tubing';

export class WeightingDB_controller {
  constructor(private db: AngularFireDatabase) {
  }


  /************** Weighting ****************/
  async getWeightingList(): Promise<Weighting[]> {
    let WeightList: Weighting[] = [];
    var snapshot = await this.db.database.ref('QC').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{

        let weight = new Weighting();
        weight.PO_No = childSnapshot.key;

        var part_trackList: WeightPartList[] = [];
        childSnapshot.forEach( (childSnapshot2)=> {
          let weight_track = new WeightPartList;

          let box_info = new BoxInfo;
          box_info.Status = childSnapshot2.child('Weighting/Status').val();
          box_info.Updated_Date = new Date(childSnapshot2.child('Weighting/Date').val()) || null;
          box_info.MadeToOrder = childSnapshot2.child("Weighting/MadeToOrder").val();
          box_info.MakeToStock = childSnapshot2.child("Weighting/MakeToStock").val();
          box_info.RackingNo = childSnapshot2.child("Weighting/RackingNo").val();
          box_info.WarehouseLocation = childSnapshot2.child("Weighting/WarehouseLocation").val();

          box_info.Category = parseFloat(box_info.MadeToOrder) === 0? "Made To Order": "Made To Stock";

          childSnapshot2.child('Weighting/Taken by PO').forEach(e=>{
            var t = new TakenbyPO();
            t.name = e.key
            t.quantity = e.val();
            box_info.Packaging_Quantity = (parseFloat(box_info.Packaging_Quantity) + t.quantity).toString();
            box_info.TakenbyPO.push(t);
          })

          var rejectedQty = 0;
          childSnapshot2.child('Rejected Part').forEach((childSnapshot3)=>{
            let rej = new RejectedInfo();
            rej.Code = childSnapshot3.key;
            rej.Quantity = childSnapshot3.val();
            rejectedQty += rej.Quantity;
            weight_track.RejectedInfos.push(rej);
          })

          weight_track.Part_No = childSnapshot2.key;
          weight_track.Accept_Qty = childSnapshot2.child("Accepted Qty").val();

          weight_track.Weight_Date = new Date(moment(childSnapshot2.child("QC Date").val()).format());
          // weight_track.Reject_Remark = childSnapshot2.child("Remark").val();
          weight_track.Reject_Qty = rejectedQty;
          weight_track.WorkingArea = childSnapshot2.child("Working Area").val();
          weight_track.UpdateBy = childSnapshot2.child("Update_by").val();

          weight_track.WeightingInfo = box_info;
          part_trackList.push(weight_track);
        });
        weight.Weight_Part_List = part_trackList;
        WeightList.push(weight);
      });
    }
    return WeightList;
  }

  async getSpecificWeight(PO_No: string, Part_No: string): Promise<Weighting> {
    let weighting = new Weighting();
    var snapshot = await this.db.database.ref('QC/' + PO_No).once('value');

    if (snapshot.exists()) {
      weighting.PO_No = snapshot.key;

      var weight_track: WeightPartList[] = [];
      snapshot.forEach( (childSnapshot) =>{
        let WeightPart = new WeightPartList;

        WeightPart.Part_No = childSnapshot.key;
        WeightPart.Accept_Qty = childSnapshot.child('Accepted Qty').val();
        WeightPart.Weight_Date = new Date(childSnapshot.child('QC Date').val());

        var rejectedQty = 0;
        childSnapshot.child('Rejected Part').forEach((childSnapshot2)=>{
          let rej = new RejectedInfo();

          rej.Code = childSnapshot2.key;
          rej.Quantity = childSnapshot2.val();
          rejectedQty += parseInt(rej.Quantity.toString());
          WeightPart.RejectedInfos.push(rej);
        });

        weight_track.push(WeightPart);
      });

      weighting.Weight_Part_List = weight_track;
    }

    return weighting;
  }

  async getScheduleTubingInfo(PO_No: string, Part_No: string): Promise<Tubing> {
    let tubing = new Tubing();
    var snapshot = await this.db.database.ref('ManualTubing/' + PO_No + '/' + Part_No).once('value');

    if (snapshot.exists()) {
      tubing.PartNumber = snapshot.key;
      tubing.PONumber = PO_No;
      tubing.ProductionQuantity = snapshot.child('ProductionQty').val();
      tubing.CreatedDate = snapshot.child('CreatedDate').val();
      tubing.UpdatedDate = snapshot.child('UpdatedDate').val();
      tubing.UpdatedBy = snapshot.child('UpdatedBy').val();

      snapshot.child('Tubings').forEach((childSnapshot2) => {
        let tubingInfo = new TubingInfo;
        tubingInfo.TubingAmount = parseFloat(childSnapshot2.val());
        tubingInfo.TimeStamp = childSnapshot2.key;
        tubing.TubingInfos.push(tubingInfo);
      });
    }

    return tubing;
  }

  async getScheduleAutoTubingInfo(PO_No: string, Part_No: string): Promise<Tubing> {
    let tubing = new Tubing();
    var snapshot = await this.db.database.ref('AutoTubing/' + PO_No + '/' + Part_No).once('value');

    if (snapshot.exists()) {
      tubing.PartNumber = snapshot.key;
      tubing.PONumber = PO_No;
      tubing.ProductionQuantity = snapshot.child('ProductionQty').val();
      tubing.CreatedDate = snapshot.child('CreatedDate').val();
      tubing.UpdatedDate = snapshot.child('UpdatedDate').val();
      tubing.UpdatedBy = snapshot.child('UpdatedBy').val();

      snapshot.child('Tubings').forEach((childSnapshot2) => {
        let tubingInfo = new TubingInfo;
        if(childSnapshot2.val().toString() != '0.0' && childSnapshot2.val().toString() != '0.00'){
          tubingInfo.TubingAmount = childSnapshot2.val();
          tubingInfo.TimeStamp = childSnapshot2.key;
          tubing.TubingInfos.push(tubingInfo);
        }
        
      });
    }

    return tubing;
  }

  /*async search_Weight(PO_No: string): Promise<Weighting> {
    let searched_weight: Weighting;
    var snapshot = await this.db.database.ref('Weighting').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot)=> {
        var get_PO_No = childSnapshot.key;
        if (PO_No.match(get_PO_No)) {
          searched_weight.PO_No = get_PO_No;
          var part_trackList: WeightPartList[] = [];
          childSnapshot.forEach( (childSnapshot2)=> {
            let weight_track = new WeightPartList;
            var boxList : BoxInfo[] = [];
            childSnapshot2.child('Weighting').forEach((childSnapshot3)=>{
              let box_info = new BoxInfo;
              box_info.Box_No = childSnapshot3.key.split('@@')[2];
              // box_info.Box_Status = childSnapshot3.child('Box Status').val();
              box_info.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
              box_info.Status = childSnapshot3.child('Status').val();
              // box_info.Weighting_Status = childSnapshot3.child('Weighting Status').val();
              box_info.Updated_Date = new Date(childSnapshot3.child('Date').val());
              box_info.Category = childSnapshot3.child("Category").val();

              boxList.push(box_info);
            })
            var rejectedQty = 0;
            childSnapshot2.child('Rejected Part').forEach((childSnapshot3)=>{
              let rej = new RejectedInfo();
              rej.Code = childSnapshot3.key;
              rej.Quantity = childSnapshot3.val();
              rejectedQty += rej.Quantity;
              weight_track.RejectedInfos.push(rej);
            })

            weight_track.Part_No = childSnapshot2.key;
            weight_track.Accept_Qty = childSnapshot2.child("Accepted Qty").val();
            if(weight_track.Accept_Qty){
              searched_weight.Type = "QC"
            }else{
              searched_weight.Type = "STOCK"
            }
            weight_track.Weight_Date = new Date(childSnapshot2.child("QC Date").val());

            // weight_track.Reject_Remark = childSnapshot2.child("Remark").val();
            weight_track.Reject_Qty = rejectedQty;
            weight_track.WorkingArea = childSnapshot2.child("Working Area").val();

            weight_track.WeightingInfo = boxList;
            part_trackList.push(weight_track);
          });
          searched_weight.Weight_Part_List = part_trackList;
        }
      });
    }
    return searched_weight;
  }*/

  delete_Weight(PO_No: string, Part_No: string) {
    this.db.database.ref('/Weighting/' + PO_No + '/' + Part_No).set(null);
  }


}
