import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.css']
})
export class AddScheduleComponent implements OnInit {

  poNo: any;
  lotNumber: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  partW: string;
  partL: string;
  partT: string;
  partG: string;
  part: Part = new Part();
  machine: Machine = new Machine();
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  po_controller: PODB_controller = new PODB_controller(this.db);
  machineList: Machine[] = [];
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  availableMachineList2: any[] = [];
  selectedMachineList2: any[] = [];
  partlist: Part[] = [];
  matSelectPart: Part[] = [];
  clone: Part[] = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  newPO: PurchaseOrder = new PurchaseOrder();
  newPO_PartList: PartTracker[] = [];
  startDate1 = [];
  endDate1 = [];
  startDate2 = [];
  endDate2 = [];
  email: string;
  formvalid = [];
  searchPart: any;

  check = false;
  forecastChecked = false;
  plantChecked = false;
  partitionChecked = false;
  existingName = false;
  blowingCheck = false;
  cuttingCheck = false;

  @ViewChild('picker', { static: true }) picker: any;

  public date: moment.Moment;
  public minDate: Date;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [{ value: true, label: 'True' }, { value: false, label: 'False' }];
  private dateFormat = new DateFormatService();

  constructor(
    private dialogRef: MatDialogRef<AddScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
  ) {
    this.addForm = this.fb.group({lotNumber: '', po: '', parts: this.fb.array([])});
    this.minDate = new Date();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
    this.addParts();
  }

  async nameValidation() {
    const lotNumber = this.addForm.get('lotNumber').value;
    var snapshot = await this.db.database.ref('Purchase Order/' + lotNumber).once('value');

    if (snapshot.exists()) {
      this.existingName = true;
    }
    else if (lotNumber.includes('/') || lotNumber.includes('@') || lotNumber.includes('[')  || lotNumber.includes(']')  || lotNumber.includes('.')  || lotNumber.includes('#')  || lotNumber.includes('$') || lotNumber.includes('*') ) {
      this.existingName = true
    }
    else{
      this.existingName = false;
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.clone = this.matSelectPart.slice();
      this.spinner.hide();
    });
  }

  change() {
    if (this.forecastChecked) {
      this.addForm.get('lotNumber').disable();
      this.addForm.get('lotNumber').setValue('');
    }
    else {
      this.addForm.get('lotNumber').enable();
    }
  }


  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }

  newParts(): FormGroup {
    const partControl = new FormControl();
    const partfilterControl = new FormControl();
    const index = this.parts().length;

    partfilterControl.valueChanges.subscribe((value) => {
      this.findPart(partfilterControl);
    });

    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        this.searchPart = partControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partControl,
      partfilterControl,
      partNumber: '',
      partDescription: '',
      partW: '',
      partL: '',
      partT: '',
      partG: '',
      material: '',
      colourBase: '',
      frontPrinting: '',
      backPrinting: '',
      quantity: '',
      availableStock: '',
      raw: '',
      poquantity: '',
      remark:'',
      flag:false,
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));
  }

  addParts() {
    this.parts().push(this.newParts());
    this.availableMachineList.push(null);
    this.selectedMachineList.push(null);
    this.availableMachineList2.push(null);
    this.selectedMachineList2.push(null);
    this.partlist.push(null);
    this.runningScheduleList.push(null);
    this.estimateTotalTime.push(null);
    this.startDate1.push(null);
    this.endDate1.push(null);
    this.startDate2.push(null);
    this.endDate2.push(null);
    this.formvalid.push(true);
    const info = {part: "", index: -1,}
    this.choosingPart.push(info);
  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.availableMachineList.splice(i, 1);
    this.availableMachineList2.splice(i, 1);
    this.partlist.splice(i, 1);
    this.runningScheduleList.splice(i, 1);
    this.estimateTotalTime.splice(i, 1);
    this.startDate1.splice(i, 1);
    this.endDate1.splice(i, 1);
    this.startDate2.splice(i, 1);
    this.endDate2.splice(i, 1);
    this.formvalid.splice(i, 1);
    this.choosingPart.splice(i,2);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if (this.existingName) {
      this.toast.error('Invalid character("/@") inside the Lot name ', 'Please change!');
      return;
    }

    const addPOModel = {
      POName: this.addForm.get('po').value,
      LotNumber: this.addForm.get('lotNumber').value,
      parts: []
    };
    const parts = this.addForm.get('parts').value;

    parts.forEach(async (element, index) => {
      if (!this.selectedMachineList[index] && this.blowingCheck && !this.plantChecked) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }

      if (this.forecastChecked){
        element.poquantity = 0;
      }

        const info = {
          durationHrs: this.estimateTotalTime[index],
          partNumber: element.partControl,
          partName: this.partlist[index].Part_Name,
          productionQuantity: element.quantity,
          rawMaterial: this.partlist[index].Raw_Material,
          poQuantity: element.poquantity,
          remark: element.remark,
          scheduleID: uuidv4(),
        };

        if(isNaN(parseFloat(element.poquantity))){
          this.toast.error('Part information(' + (index + 1) + ') PO quantity not completed!', 'Please fill in');
          return;
        }else if(parseFloat(element.poquantity) === 0 && !this.forecastChecked){
          this.toast.error('Part information(' + (index + 1) + ') PO quantity cant be 0', 'Please fill in');
          return;
        }
        addPOModel.parts.push(info);
    });

    if (addPOModel.parts.length === 0) {
      this.toast.error('Please add at least one part', 'Add part');
      return;
    }

    if (!addPOModel.POName && !this.forecastChecked ) {
      this.toast.error('Please fill in the Lot name', 'Please fill in');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this schedule?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        if (!this.forecastChecked) {
          await this.po_controller.addSchedule(addPOModel, this.email);
          this.spinner.hide();
          this.toast.success('Add schedule (Production Order/Purchase Order) successful');
          this.dialogRef.close(addPOModel);
        }
        else {
          addPOModel.POName = this.getRandomString(5) + '-FORECAST';
          await this.po_controller.addSchedule(addPOModel, this.email);
          this.spinner.hide();
          this.toast.success('Add schedule (Production Order/Purchase Order) successful');
          this.dialogRef.close(addPOModel);
        }
      }
    });
  }

  getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  choosingPart = [];

  getMachineForPart(partNumber: any, formindex) {
    this.spinner.show();
    const samePart = this.choosingPart.find(c => c.part === partNumber && c.index !== formindex);

    if (samePart) {
      this.toast.warning("Same part number has been ordered, please choose others", "Warning");
      this.partlist[formindex] = null;
      this.spinner.hide();
      return;
    }

    if (partNumber) {
      this.part_controller.search_Part(partNumber).then(data => {
        this.partlist[formindex] = data;

        $('[name="partDescription"]').val(data.Part_Name);
        $('[name="partW"]').val(data.Part_Width + ' ' +  data.widthUnit.toLowerCase());
        $('[name="partL"]').val(data.Part_Length + ' ' +   data.lengthUnit.toLowerCase());
        $('[name="partT"]').val(data.Part_Thickness + ' ' +   data.thicknessUnit.toLowerCase());
        $('[name="partG"]').val(data.Part_Gusset + ' ' +   data.gussetUnit.toLowerCase());
        $('[name="material"]').val(data.Part_Material);
        $('[name="colourBase"]').val(data.Color);
        $('[name="frontPrinting"]').val(data.Front_Printing);
        $('[name="backPrinting"]').val(data.Back_Printing);

        $('[name="flip"]').val(data.Flip);
        $('[name="air"]').val(data.Air_Gaps);
        $('[name="hole"]').val(data.Holes);
        $('[name="cutting"]').val(data.Cutting);
        const info = {part: partNumber, index: formindex};
        this.choosingPart[formindex] = info;
      });
    }
    this.spinner.hide();
  }

  estimateTime(partQuantity: any, formindex) {
    this.estimateTotalTime[formindex] = partQuantity * (parseFloat(this.partlist[formindex].ExtrusionCycleTime)/3600);

    if (this.addForm.get('parts').value[formindex].quantity) {
      this.viewRaw(this.partlist[formindex].Raw_Material, formindex)
    }
  }

  showDisplayTime(time){
    let display = '';
    if(time >= 1){
        display +=('0' +  Math.floor(time).toString()).slice(-2) + ' hrs '
        time = time - Math.floor(time);
    }
    if(time > 0){
        let min = time * 60;
        display += ('0' + Math.floor(min).toString()).slice(-2) + ' mins '
        time = min - Math.floor(min);
    }
    if(time > 0){
      let sec = time * 60;
      display += ('0' + Math.floor(sec).toString()).slice(-2) + ' secs '
    }

    return display;

  }


  viewRaw(materials, formindex, value = 0, part2 = null, part3 =null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {top: '5%'};
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    var quantity = this.addForm.get('parts').value[formindex].quantity;
    var partInfo = this.partlist[formindex];
    var multiple = value;

    dialogConfig.data = { materials, quantity, partInfo, multiple};

    this.dialog.open(ViewRawMaterialsComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result.Material) {
        materials.forEach((element, index) => {
          element.check = result.Material[index].check;
          element.quantityNeeded = result.Material[index].quantityNeeded;
        });
      }
    });
  }

  parFloat(value) {
    return parseFloat(value);
  }

  previewPrice(raw, formindex) {
    var total = 0;
    total += parseFloat(this.partlist[formindex].CartonBox.Unit_Price) * parseFloat(this.partlist[formindex].cartonNeeded);

    raw.forEach((element, index) => {
      if (element.check) {
        total += parseFloat(element.Unit_Price) * parseFloat(element.quantityNeeded);
      }

    });

    if (this.partlist[formindex].innerCheck) {
      total += parseFloat(this.partlist[formindex].InnerBox.Unit_Price) * parseFloat(this.partlist[formindex].innerBoxNeeded);
    }
    if (this.partlist[formindex].polyCheck) {
      total += parseFloat(this.partlist[formindex].PolyBag.Unit_Price) * parseFloat(this.partlist[formindex].polyBagNeeded);
    }
    if (isNaN(total)) {
      return 'None'
    }

    return "RM" + total.toFixed(2);

  }

  checkStock(value){
    const part = this.clone.find(p=>p.Part_No === value);
    if(part){
      this.toast.info("Stock quantity: " + part.Stock_Quantity,'Part No: ' +part.Part_No,{
        positionClass: 'toast-top-center'
     })
    }

  }
  Stock(value){
    if(value){
      const part = this.clone.find(p=>p.Part_No === value);
      return part.Stock_Quantity;
    }
    return ''
  }

  tooltips(value){
    const part = this.clone.find(p=>p.Part_No === value);
    if(part){
      return "Stock quantity: " + part.Stock_Quantity
    }
  }
}
