import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { timeEnd } from 'console';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { AddScheduleComponent } from '../add-schedule/add-schedule.component';

@Component({
  selector: 'app-assign-schedule',
  templateUrl: './assign-schedule.component.html',
  styleUrls: ['./assign-schedule.component.css']
})
export class AssignScheduleComponent implements OnInit {


  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  po_controller: PODB_controller = new PODB_controller(this.db);
  machineList: Machine[] = [];
  email: string;
  unAssignedList: PurchaseOrder[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
  ) {

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })

  }

  ngOnInit() {
    this.spinner.show();
    this.machine_controller.getMachineListWithWaitingSchedule().then(e=>{
      this.machineList = e;
      this.machineList = this.machineList.sort((a,b)=>
      {
        return  a.Brand > b.Brand ? 1:-1
      })
      this.machineList.forEach(e=>{
        e.Schedule_Track = e.Schedule_Track.sort((a,b)=> a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date))
      })
      this.spinner.hide();
    })
    this.po_controller.getAllUnassignedPO().then(e=>{
      this.unAssignedList = e;
    })
  }

  showDisplayTime(time){
    let display = '';
    if(time >= 1){
        display +=('0' +  Math.floor(time).toString()).slice(-2) + ' hrs '
        time = time - Math.floor(time);
    }
    if(time > 0){
        let min = time * 60;
        display += ('0' + Math.floor(min).toString()).slice(-2) + ' mins '
        time = min - Math.floor(min);
    }
    if(time > 0){
      let sec = time * 60;
      display += ('0' + Math.floor(sec).toString()).slice(-2) + ' secs '
    }

    return display;

  }

  cancel() {
    this.dialogRef.close(false);
  }

  assign(po: PurchaseOrder, p: PartTracker, machine: Machine){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm assign this schedule to this machine ' + machine.Brand + '?';
    let timeStart; let timeEnd;
    if(machine.Schedule_Track.length > 0){

      let lastSchedule = machine.Schedule_Track[machine.Schedule_Track.length-1];

      if(lastSchedule.DateFormatEnd.getTime() > new Date().getTime())
      {
        timeStart= lastSchedule.DateFormatEnd
      }else{
        timeStart = new Date();
      }
    }else{
      timeStart = new Date();
    }
    timeEnd = new Date(timeStart.getTime() + (p.Duration * 60 * 60000));

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        await this.po_controller.assignPoToMachine(po, p, machine, this.email,timeStart, timeEnd);
        this.toast.success('Assign Successful');
        this.ngOnInit();
        this.spinner.hide();
      }
    });
  }

  UnAssigned(schedule: ScheduleTracker, machine: Machine){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm unassign this schedule '+ schedule.LotNumber +' from this machine ' + machine.Brand + '?';


    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        await this.po_controller.unassignPoFromMachine(schedule, machine, this.email);
        this.toast.success('Unassign Successful');
        this.ngOnInit();
        this.spinner.hide();
      }
    });
  }


}
