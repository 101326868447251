import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-view-raw-materials',
  templateUrl: './view-raw-materials.component.html',
  styleUrls: ['./view-raw-materials.component.css']
})
export class ViewRawMaterialsComponent implements OnInit {

  RawMaterial: RawMaterialInfo[] = [];
  partInfo : any;
  polyCheck = false;
  innerCheck = false;
  multiple: number;


  constructor(
    private dialogRef: MatDialogRef<ViewRawMaterialsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    this.multiple = data.multiple
    this.partInfo = data.partInfo;
    this.RawMaterial = cloneDeep(data.materials);

    var total;
    this.RawMaterial.forEach((element,index) => {
        let cal1 = 0;
        cal1 +=  (parseFloat(element.Raw_Amount)/100) * parseFloat(data.quantity);
        element.quantityNeeded = (cal1).toFixed(2);
        total = element.quantityNeeded;
        element.check = true;
    });

    // this.RawMaterial.forEach(element=>{
    //   if(element.Raw_Type === 'Material Batch')
    //     element.quantityNeeded = ((parseFloat(total) * parseFloat(element.Mat_Ratio) / 100)).toFixed(2)
    //   else if(element.Raw_Type === 'Pigment')
    //     element.quantityNeeded = ((parseFloat(total) / 25 )).toFixed(2)

    // });
  }

  ngOnInit(){}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(){
    const info={
      Material :this.RawMaterial,
      polyCheck :this.polyCheck,
      innerCheck :this.innerCheck,
    }
    this.dialogRef.close(info);
  }

  parFloat(value){
    return parseFloat(value);
  }

  iNaN(value){
    return isNaN(value);
  }

}
