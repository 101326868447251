import { Schedule } from './../Object_Classes/Schedule/Schedule';
import { Machine } from './../Object_Classes/Machine/Machine';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  convertDateIntoYearMonthDay(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year.toString() + ("0" + month.toString()).slice(-2) + ("0" + day.toString()).slice(-2);

  }

  checkCrushDate(startdatelist: Date[], enddatelist: Date[], machinelist: Machine[]){
    for (let i = 0; i < startdatelist.length; i++) {
      for (let j = 0; j < startdatelist.length; j++) {
        if(j===i)
          continue;
        if (startdatelist[j].getTime() >= startdatelist[i].getTime() &&
          startdatelist[j].getTime() <= enddatelist[i].getTime() &&
          machinelist[j].Machine_No === machinelist[i].Machine_No) {
          return true;
        }

        if (enddatelist[j].getTime() >= startdatelist[i].getTime() &&
          enddatelist[j].getTime() <= enddatelist[i].getTime() &&
          machinelist[j].Machine_No === machinelist[i].Machine_No) {
          return true;
        }
      }
    }
    return false;
  }

  validateSchedulingMachine(startDate: Date, enddDate: Date, Machine: Machine, ScheduleNo = null){

    for (let index = 0; index < Machine.Schedule_Track.length; index++) {
      console.log(ScheduleNo)
      console.log(Machine.Schedule_Track[index].Machine_Schedule_No)

      if(Machine.Schedule_Track[index].Machine_Schedule_No === ScheduleNo)
        continue;
      if (startDate.getTime() >= Machine.Schedule_Track[index].DateFormatStart.getTime() &&
      startDate.getTime() <= Machine.Schedule_Track[index].DateFormatEnd.getTime()) {
      return true;
      }
      if (enddDate.getTime() >= Machine.Schedule_Track[index].DateFormatStart.getTime() &&
      enddDate.getTime() <= Machine.Schedule_Track[index].DateFormatEnd.getTime()) {
      return true;
      }
    }

    return false;
  }


}
