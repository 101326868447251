export class Customer{
  private _Customer_ID: string;
  private _Customer_Name: string;
  private _Person_In_Charge: string;
  private _Contact: string;
  private _Email: string;
  private _Address: string;
  private _Last_Updated: Date;
  private _Created_Date: Date;
  private _Updated_By: string;
  private _Created_By: string;
  private _Currency: string;
  private _Debtor_No: string;
  private _Remark: string;
  private _Fax: string;
  private _Area: string;
  private _Agent: string;
  private _Term: string;
  private _GSTNum: string;

  get Debtor_No(): string {
    return this._Debtor_No;
  }

  set Debtor_No(value: string) {
    this._Debtor_No = value;
  }

  get GSTNum(): string {
    return this._GSTNum;
  }

  set GSTNum(value: string) {
    if (value !== '') {
      this._GSTNum = value;
    } else {
      this._GSTNum = '-';
    }
  }
  
  get Term(): string{
    return this._Term;
  }

  set Term(value: string){
    this._Term = value;
  }
  
  get Agent(): string{
    return this._Agent;
  }

  set Agent(value: string){
    this._Agent = value;
  }
  
  get Area(): string{
    return this._Area;
  }

  set Area(value: string){
    this._Area = value;
  }

  get Fax(): string{
    return this._Fax;
  }

  set Fax(value: string){
    this._Fax = value;
  }

  get Remark(): string{
    return this._Remark;
  }

 set Remark(value: string){
   this._Remark = value;
 }

  get Currency(): string {
    return this._Currency;
  }
  set Currency(value: string) {
    this._Currency = value;
  }

  get Person_In_Charge(): string {
    return this._Person_In_Charge;
  }
  set Person_In_Charge(value: string) {
    this._Person_In_Charge = value;
  }


  get Customer_ID(): string {
    return this._Customer_ID;
  }
  set Customer_ID(value: string) {
    this._Customer_ID = value;
  }
  get Customer_Name(): string {
    return this._Customer_Name;
  }
  set Customer_Name(value: string) {
    this._Customer_Name = value;
  }

  get Contact(): string {
    return this._Contact;
  }
  set Contact(value: string) {
    this._Contact = value;
  }

  get Email(): string {
    return this._Email;
  }
  set Email(value: string) {
    this._Email = value;
  }

  get Address(): string {
    return this._Address;
  }
  set Address(value: string) {
    this._Address = value;
  }

  get Last_Updated(): Date {
    return this._Last_Updated;
  }
  set Last_Updated(value: Date) {
    this._Last_Updated = value;
  }
  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }

  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }

}
