export class Invoice {

  private _Invoice_No: string;
  private _Total_Price: string;
  private _Created_By: string;
  private _Created_Date: Date;
  private _Updated_By: string;
  private _Updated_Date: Date;
  public Currency: string;
  public Status : string;
  public Invoice_PackingList: Invoice_PackingList[] = [];

  set Invoice_No(value: string) {
    this._Invoice_No = value;
  }

  get Invoice_No(): string {
    return this._Invoice_No;
  }

  set Total_Price(value: string) {
    this._Total_Price = value;
  }

  get Total_Price(): string {
    return this._Total_Price;
  }

  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }
  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }
  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }


}


export class Invoice_PO_List {
  private _PO_No: string;
  private _Invoice_PO_Part_List: Invoice_PO_Part_List[];
  //private _Delivery_Order: string;
  private _Price: string;


  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get Invoice_PO_Part_List(): Invoice_PO_Part_List[] {
    return this._Invoice_PO_Part_List;
  }
  set Invoice_PO_Part_List(value: Invoice_PO_Part_List[]) {
    this._Invoice_PO_Part_List = value;
  }

  // set Delivery_Order(value: string) {
  //   this._Delivery_Order = value;
  // }

  // get Delivery_Order(): string {
  //   return this._Delivery_Order;
  // }

  set Price(value: string) {
    this._Price = value;
  }

  get Price(): string {
    return this._Price;
  }
}

export class Invoice_PO_Part_List {
  private _Part_No: string;
  private _Part_Quantity: number;
  private _Is_Shipped: string;
  private _Shipping_Date: Date;
  public MouldPrice: string;
  public PartPrice: string;

  get Part_No(): string {
    return this._Part_No;
  }
  set Part_No(value: string) {
    this._Part_No = value;
  }

  get Part_Quantity(): number {
    return this._Part_Quantity;
  }
  set Part_Quantity(value: number) {
    this._Part_Quantity = value;
  }

  get Is_Shipped(): string {
    return this._Is_Shipped;
  }
  set Is_Shipped(value: string) {
    this._Is_Shipped = value;
  }

  get Shipping_Date(): Date {
    return this._Shipping_Date;
  }
  set Shipping_Date(value: Date) {
    this._Shipping_Date = value;
  }
}

export class Invoice_PackingList{
  public PackingListNo: string;
  public POList : Invoice_PO_List[] =[];
}
