import { StocksComponent } from './PopUpModal/stocks/stocks.component';
import { ToastrService } from 'ngx-toastr';
import { RawMaterialsPartComponent } from './../../Shared/raw-materials-part/raw-materials-part.component';
import { DetailsComponent } from './PopUpModal/details/details.component';
import { UpdatePartComponent } from './PopUpModal/update-part/update-part.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { AddPartComponent } from './PopUpModal/add-part/add-part.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.css']
})
export class PartsComponent implements OnInit {


  partlist: Part[] = [];
  partDB_controllers: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);

  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  ClonePartList: Part[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  //Pagination warehouse
  lengthWarehouse = 0;
  pageSizeWarehouse = 10;
  pageIndexWarehouse = 0;
  offsetWarehouse = this.pageSizeWarehouse * this.pageIndexWarehouse;
  searchWarehouse: string;
  sorteduWarehouse = {
    active: '',
    direction: ''
  };
  ClonePartListWarehouse: Part[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginatorWarehouse: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginatorWarehouse: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private excelHelper: ExcelHelperService
  ) {
  }

  ngOnInit() {
    this.setup();
  }
  refresh(){
    this.setup();
  }

  setup() {
    this.spinner.show();
    this.partDB_controllers.getPartList().then(data => {
      this.partlist = data;
      this.length = this.partlist.length;
      this.lengthWarehouse = this.partlist.length;
      this.ClonePartList = this.partlist.slice();
      this.ClonePartListWarehouse = this.partlist.slice();
      this.limitList();
      this.limitListWarehouse();

      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
    });
  }

  addPart(): void {
    const dialogRefaddPart = this.dialog.open(AddPartComponent, {
      width: '90%',
      height: '90%'
    });

    var newPart = new Part();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newPart = result;
        this.spinner.show();
        this.partDB_controllers.add_or_update_Part(newPart);
        this.toast.success(newPart.Part_No + " has been added successfully!","Added Successfully")
        this.setup();

      }
    });
  }

  viewPartDetail(part): void {
  var updatePart = new Part();
  updatePart = part;
  const dialogRefupdatePart = this.dialog.open(UpdatePartComponent, {
    data: updatePart,
    width: '90%',
    height: '90%',
  });

  dialogRefupdatePart.afterClosed().subscribe(async result => {
    if (typeof result !== 'undefined') {
      updatePart = result;
      this.spinner.show();
      await this.partDB_controllers.add_or_update_Part(updatePart);
      setTimeout(() => {
        this.toast.success(updatePart.Part_No + " has been updated successfully!","Updated Successfully")
        this.setup();

      }, 2000);

    }
  });
  }


  deletePart(part: Part): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Do you really want to delete this part? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.partDB_controllers.delete_Part(part.Part_No);
        this.toast.success(part.Part_Name + " has been deleted successfully!","Deleted Successfully")
        this.setup();
      }
    })
  }


  DynamicSearchPartNumber(partNum: string): void {
    this.search = partNum;
    this.ClonePartList = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Mould_Material).toLowerCase().includes(this.search.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.ClonePartList.length;
    this.sortPartList();
    this.limitList();
  }
  DS() {
    this.ClonePartList = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Mould_Material).toLowerCase().includes(this.search.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.ClonePartList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePartList = this.partlist.slice();
    }
    this.sortPartList();
    this.limitList();
  }
  limitList() {
    this.ClonePartList = this.ClonePartList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPartList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePartList = this.ClonePartList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePartList = this.partlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePartList = this.partlist.slice();
      this.limitList();
      return;
    }

    this.ClonePartList = this.ClonePartList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
    this.limitList();
  }

  DynamicSearchPartNumberWarehouse(partNum: string): void {
    this.searchWarehouse = partNum;
    this.ClonePartListWarehouse = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      || String(u.Mould_Reference_No).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      );
    this.lengthWarehouse = this.ClonePartListWarehouse.length;
    this.sortPartListWarehouse();
    this.limitListWarehouse();
  }

  DSWarehouse() {
    this.ClonePartListWarehouse = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      || String(u.Mould_Reference_No).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      );
    this.lengthWarehouse = this.ClonePartListWarehouse.length;
  }

  paginatorWarehouse(pageEvent: PageEvent) {
    this.pageSizeWarehouse = pageEvent.pageSize;
    this.pageIndexWarehouse = pageEvent.pageIndex;
    this.offsetWarehouse = this.pageSizeWarehouse * this.pageIndexWarehouse;

    if (this.topPaginatorWarehouse.pageIndex < this.pageIndexWarehouse) {
      this.topPaginatorWarehouse.nextPage();
    } else if (this.topPaginatorWarehouse.pageIndex > this.pageIndexWarehouse) {
      this.topPaginatorWarehouse.previousPage();
    }
    if (this.bottomPaginatorWarehouse.pageIndex < this.pageIndexWarehouse) {
      this.bottomPaginatorWarehouse.nextPage();
    } else if (this.bottomPaginatorWarehouse.pageIndex > this.pageIndexWarehouse) {
      this.bottomPaginatorWarehouse.previousPage();
    }
    if (this.searchWarehouse) {
      this.DSWarehouse();
    } else {
      this.ClonePartListWarehouse = this.partlist.slice();
    }
    this.sortPartListWarehouse();
    this.limitListWarehouse();
  }

  limitListWarehouse() {
    this.ClonePartListWarehouse = this.ClonePartListWarehouse.slice(this.offsetWarehouse, (this.offsetWarehouse + this.pageSizeWarehouse));
  }

  sortPartListWarehouse() {
    if (!this.sorteduWarehouse.active || this.sorteduWarehouse.direction === '') {
      return;
    }
    this.ClonePartListWarehouse = this.ClonePartListWarehouse.sort((a, b) => {
      const isAsc = this.sorteduWarehouse.direction === 'asc';
      switch (this.sorteduWarehouse.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
  }

  sortDataWarehouse(sort: Sort) {
    this.sorteduWarehouse = sort;
    this.ClonePartListWarehouse = this.partlist.slice();
    if (this.searchWarehouse) {
      this.DSWarehouse();
    }
    if (!sort.active || sort.direction === '' && !this.searchWarehouse) {
      this.ClonePartListWarehouse = this.partlist.slice();
      this.limitListWarehouse();
      return;
    }

    this.ClonePartListWarehouse = this.ClonePartListWarehouse.sort((a, b) => {
      const isAsc = this.sorteduWarehouse.direction === 'asc';
      switch (this.sorteduWarehouse.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
    this.limitListWarehouse();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  viewRaw(materials) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {materials};

    this.dialog.open(RawMaterialsPartComponent, dialogConfig).afterClosed().subscribe(result => {

    })
  }

  view(part: Part, statement) {
    var info;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    switch (statement) {
      case 'cust':
        info = {
          title: 'Customer Preference',
          data: [{
            'label': 'Packing Information',
            'value': part.BagType,
            'size': 'col-lg-12'

          },
          {
            'label': 'Small Packing',
            'value': part.SmallPacking,
            'size': 'col-lg-3'

          },
          {
            'label': 'UOM',
            'value': part.UOM_SmallPacking,
            'size': 'col-lg-3'

          },
          {
            'label': 'Bag/Carton/Roll',
            'value': part.BagTypePacking,
            'size': 'col-lg-3'
          },
          {
            'label': 'UOM',
            'value': part.UOM_BagTypePacking,
            'size': 'col-lg-3'
          },
          ]
        }
        break;
      case 'work':
        info = {
          title: 'Work Information',
          data: [{
            'label': 'Flip',
            'value': part.Flip,
            'size': 'col-lg-3'

          },
          {
            'label': 'Air Gaps',
            'value': part.Air_Gaps,
            'size': 'col-lg-3'

          },
          {
            'label': 'Holes',
            'value': part.Holes,
            'size': 'col-lg-3'

          },
          {
            'label': 'Cutting',
            'value': part.Cutting,
            'size': 'col-lg-3'

          },
          ]
        }
        break;
      case 'secondary':
        info = {
          title: 'Production Parameters',
          data: [{
            'label': 'Cutting Cycle Time',
            'value': part.CuttingCycleTime,
            'size': 'col-lg-6'

          },
            {
            'label': 'Extrusion Cycle Time',
            'value': part.ExtrusionCycleTime,
            'size': 'col-lg-6'

          },
          {
            'label': 'Counter Per Tubing',
            'value': part.Counter_Per_Kg,
            'size': 'col-lg-6'

          },
            {
            'label': 'Machine Number',
            'value': part.Available_Machine,
            'size': 'col-lg-6'

          },

          ]
        }
        break;
      case 'stock':
        dialogConfig.data = {
          type: 'stock',
          Part_No: part.Part_No
        }
        this.dialog.open(StocksComponent, dialogConfig)
        return;
      case 'reserved':
        dialogConfig.data = {
          type: 'reserved',
          stock: part.ReservedInfo,
          Part_No: part.Part_No
        }
        this.dialog.open(StocksComponent, dialogConfig)
        return;
    }
    this.dialog.open(DetailsComponent, {
      width: '80%',
      height: 'auto',
      data: info
    });

  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    for (const part of this.partlist) {
      // const material = part.Raw_Material.find(r=>r.Raw_Type === 'Raw Material');
      // const pigment = part.Raw_Material.find(r=>r.Raw_Type === 'Pigment');
      // const batch = part.Raw_Material.find(r=>r.Raw_Type === 'Master Batch');
      for (const p of part.Raw_Material) {

      }
      const info ={
        "Part No" : part.Part_No,
        "Part Description": part.Part_Name,
        "Extra Info": part.ExtraInfo,
        "Part Material": part.Part_Material,
        "Color Base":part.Color,
        "Front Printing":part.Front_Printing,
        "Back Printing":part.Back_Printing,
        "Width": part.Part_Width,
        "UOM(W)": part.widthUnit,
        "Length": part.Part_Length,
        "UOM(L)": part.lengthUnit,
        "Thickness": part.Part_Thickness,
        "UOM(T)": part.thicknessUnit,
        "Gusset": part.Part_Gusset,
        "UOM(G)": part.gussetUnit,
        "Flip": part.Flip,
        "Air Gaps": part.Air_Gaps,
        "Holes": part.Holes,
        "Cutting": part.Cutting,
        "Bag Type": part.BagType,
        "Small Packing":part.SmallPacking,
        "UOM(SP)": part.UOM_SmallPacking,
        "Bag/Carton/Roll":part.BagTypePacking,
        "UOM(B)":part.UOM_BagTypePacking,
        "Counter Per Tubing":part.Counter_Per_Kg,
        "Cutting Cycle Time":part.CuttingCycleTime,
        "Extrusion Cycle Time":part.ExtrusionCycleTime,







        // "Part Weight": part.Part_Weight || '-',
        // "Runner Weight": part.Part_Runner_Weight || '-',
        // "Model": part.Model|| 'N/A',
        // "No of Cavity": part.Number_Cavity|| 'N/A',
        // "Customer Licence": part.Customer_License|| 'N/A',
        // "Duty Export":part.Duty_Export|| 'N/A',
        // "Project Start Date":part.Project_Start_Date|| 'N/A',
        // "Tarif Code":part.Traffic_Code|| 'N/A',
        // "Tarif Code Name":part.Traffic_Code_Name|| 'N/A',
        // "Stock Quantity": part.Stock_Quantity || 0,
        // "Reserved Stock": part.Reserved_Stock || 0,
        // "Customer Name":part.Customer_Name || 'N/A',
        // "Purchase Order":part.PO_No || 'N/A',
        // "Part Price List Mold":part.Mould_Price || '-',
        // "Part Price List Customer":part.Part_Price || '-',
        // "MOQ Remarks":part.Remarks|| 'N/A',
        // "Mold Family Info":part.Mould_Family|| 'N/A',
        // "Mold Information":part.Mould_Info|| 'N/A',
        // "Mold Material":part.Mould_Material|| 'N/A',
        // "Mold Maker":part.Mould_Maker_Name|| 'N/A',
        // "Mold Maker Phone":part.Mould_Rack_Location|| 'N/A',
        // "Mold Reference No":part.Mould_Reference_No|| 'N/A',
        // "Mold Size":part.Mould_Size || 'N/A',
        // "Cycle Time":part.Cycle_Time || '-',
        // "Machine Tonnage":part.Machine_Tonnage || '-',
        // "Machine No":part.Available_Machine || '-',
        // "Carton Box":part.CartonBox.Material_Name || 'N/A',
        // "Quantity In Box":part.Box_Quantity || 0,
        // "Poly Bag/ Inner Box":part.PolyBag.Material_Name || part.InnerBox.Material_Name || "N/A",
        // "Quantity In Poly Bag/ Inner Box": part.Polybag_Quantity || part.Quantity_InnerBox || 0,
        // "Supplier Carton Packing": part.Supplier1,
        // "Supplier Poly Bag/ Inner Box": part.Supplier6 || "N/A",
        // "Material Ratio":material? material.Mat_Ratio : '-',
        // "Customer Material":material?material.Customer_Material : '-',
        // "SPEN Material":material?material.SPEN_Material:'-',
        // "Customer Ral":material?material.Customer_RAL: '-',
        // "Supplier 3":material?material.Supplier: "N/A",
        // "Pigment/Colour": pigment?pigment.Material_Name: 'N/A',
        // "Supplier 3(Pigment)":pigment?pigment.Supplier:'N/A',
        // "Master Batch": batch?batch.Material_Name:'N/A',
        // "Supplier 3(Batch)":batch?batch.Supplier:'N/A',
        // "Secondary Process 1":part.Sec_Pro_1,
        // "Cycle Time (Sec process 1)": part.Sec_Pro_1_Cycle_Time,
        // "Usage 1":part.Usage1|| 'N/A',
        // "Supplier 4":part.Supplier4 || 'N/A',
        // "Secondary Process 2":part.Sec_Pro_2|| 'N/A',
        // "Cycle Time (Sec process 2)":part.Sec_Pro_2_Cycle_Time|| 'N/A',
        // "Usage 2":part.Usage2 || 'N/A',
        // "Supplier 5":part.Supplier5 || 'N/A',
      }
      let index = 1;
      for (const p of part.Raw_Material) {
          info['Material'+index] = p.Material_Name,
          info['Material'+index + '_Ratio'] = p.Raw_Amount,
          index++;
      }


      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'part'+new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelWarehouse(){
    const exportInformation = [];
    this.spinner.show();
    for (const part of this.partlist) {
      const info ={
        "Part No" : part.Part_No,
        "Part Description": part.Part_Name,
        "Stock Quantity": part.Stock_Quantity || 0,
        "Reserved Stock": part.Reserved_Stock || 0,
        "Location": part.rackingNumber.slice(0, -1) || "N/A",

      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'partWarehouse'+new Date().getTime());
    this.spinner.hide();
  }
}

