import { data } from 'jquery';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { InvoiceDB_controller } from '../../../Services/DB_Controller/InvoiceDB_controller';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AngularFireStorage } from '@angular/fire/storage';
import { PackingListDB_controller } from '../../../Services/DB_Controller/PackingListDB_controller';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { StringValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { isNullOrUndefined } from 'util';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-oee-history',
  templateUrl: './oee-history.component.html',
  styleUrls: ['./oee-history.component.css']
})
export class OEEHistoryComponent implements OnInit {
  poNo = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  po_controller: PODB_controller = new PODB_controller(this.db);
  invoice_controller: InvoiceDB_controller = new InvoiceDB_controller(this.db);
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  matSelectPO: PurchaseOrder[] = [];
  matSelectPart=[];
  addForm: FormGroup;
  clone = [];
  newPO: PurchaseOrder = new PurchaseOrder();
  shippingDate = new Date();
  shipDate = [];
  searchPart: any;
  searchPO: any;
  check = false;
  check2 = false;
  selectedParts: PartTracker[] = [];
  newInvoice: Invoice = new Invoice();
  email:string;
  @ViewChild('picker', {static: true}) picker: any;
  temporaryInvoiceNum: string = "";
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  partsList: Part[] = [];
  // partUnitPrice: any[] = [];
  // partMouldPrice: any[] = [];
  // partRemark: any[] = [];
  priceList: string = "0";
  linkPackingList: PackingList = new PackingList();
  selectedCurrency: string = 'MYR';
  selectedInvoice: Invoice = new Invoice();
  packing_controller: PackingListDB_controller = new PackingListDB_controller(this.db);

  public date: moment.Moment;

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  machine:Machine;
  machineNum:string;
  monthOEE: number;
  totalNoJobHours: number;
  searchYear: string;
  searchMonth: string;
  matSelectYear : string[] = [];
  cloneYear : string[] = [];
  matSelectMonth : string[] = [];
  cloneMonth : string[] = [];
  selectedMonthJobList: ScheduleTracker[] = [];

  constructor(
    private dialogRef: MatDialogRef<OEEHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private storage : AngularFireStorage,
    private firestore: AngularFirestore,

  ) {
   this.machine = data.machineDetails;

   this.angularFireAuth.authState.subscribe(auth=>{
    this.email = auth.email;
  })

  }

  ngOnInit() {
    this.spinner.show();
    let cDate = new Date();
    this.machineNum = this.machine.Machine_No;

    for (const data of this.machine.OEE) {
      this.matSelectYear.push(data.Year);
      this.cloneYear = this.matSelectYear.slice();
      for (const data2 of data.Monthly_OEE) {
        this.matSelectMonth.push(data2.Month);
        this.cloneMonth = this.matSelectMonth.slice();
        let qMonth = data2.Month.substring(1, data2.Month.length);
        if (data.Year.match(cDate.getFullYear().toString()) &&
            qMonth.match((cDate.getMonth() + 1).toString())) {
              this.monthOEE = parseFloat((data2.This_Month_OEE / data2.Total_Jobs).toFixed(2));
              this.totalNoJobHours = data2.Total_No_Jobs_Hours;
            }
      }
    }

    for (const data3 of this.machine.Schedule_Track) {
      var endDate = new Date(data3.DateFormatEnd);
      if ((endDate.getFullYear() == cDate.getFullYear() &&
          (endDate.getMonth() + 1) == (cDate.getMonth() + 1))) {
            data3.HourLeft = parseFloat((data3.Availability * data3.Performance * data3.Effectiveness).toFixed(2));
            this.selectedMonthJobList.push(data3);
          }
    }

    this.setForm();
  }

  setForm(){
    let cDate = new Date();
    const year = new FormControl();
    const yearfilterControl = new FormControl();
    const month = new FormControl();
    const monthfilterControl = new FormControl();
    year.setValue(cDate.getFullYear().toString());
    yearfilterControl.valueChanges.subscribe(() => {
      this.findYear(yearfilterControl);
    });
    month.setValue("0" + (cDate.getMonth() + 1).toString());
    monthfilterControl.valueChanges.subscribe(() => {
      this.findMonth(monthfilterControl);
    });
    year.valueChanges.subscribe(() => {
      if (year.value && month.value) {
        this.searchYear = year.value;
        this.searchMonth = month.value;
        this.change();
      }
    });
    month.valueChanges.subscribe(() => {
      if (year.value && month.value) {
        this.searchYear = year.value;
        this.searchMonth = month.value;
        this.change();
      }
    });

    this.addForm = this.fb.group({
      year: year,
      yearfilterControl: yearfilterControl,
      month: month,
      monthfilterControl: monthfilterControl,
    });
    this.spinner.hide()

  }

  change(){
    this.spinner.show()

    for (const datas of this.machine.OEE) {
      if (datas.Year.match(this.searchYear)) {
        datas.Monthly_OEE.forEach(datass => {
          if (datass.Month.match(this.searchMonth)) {
            this.monthOEE = parseFloat((datass.This_Month_OEE / datass.Total_Jobs).toFixed(2));
            this.totalNoJobHours = datass.Total_No_Jobs_Hours;
          }
        })
      }
    }
    this.selectedMonthJobList = [];

    for (const data3 of this.machine.Schedule_Track) {
        var endDate = new Date(data3.DateFormatEnd);
        if (((endDate.getFullYear().toString()).match(this.searchYear) &&
            ("0" + ((endDate.getMonth() + 1).toString())).slice(-2).match(this.searchMonth))) {
              data3.HourLeft = parseFloat((data3.Availability * data3.Performance * data3.Effectiveness).toFixed(2));
              this.selectedMonthJobList.push(data3);
            }
    }

    this.spinner.hide()

  }

  findYear(cus){
    if (!this.cloneYear) { return; }
    const search = cus.value;
    this.matSelectYear = this.cloneYear.filter(p => p.includes(search));
  }

  findMonth(cus){
    if (!this.cloneMonth) { return; }
    const search = cus.value;
    this.matSelectMonth = this.cloneMonth.filter(p => p.includes(search));
  }

  cancel() {
    this.dialogRef.close(false);
  }
  Nan(value){
    if (isNaN(value) || isNullOrUndefined(value))
      return 0
    else
      return value
  }

  CalculateOEE(job){
    if(job.Availability && job.Performance && job.Effectiveness){
      return job.Availability * job.Performance* job.Effectiveness
    }else{
      return 0;
    }
  }
}
