import { Currency } from './../../../../Shared/Currency';
import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { timeStamp } from 'console';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.css']
})
export class AddSupplierComponent implements OnInit {

  supplier = new Supplier();
  currencyList = Currency;
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<AddSupplierComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data){
        this.supplier.Supplier_ID = data._Supplier_ID;
        this.supplier.Supplier_Name = data._Supplier_Name;
        this.supplier.Contact = data._Contact;
        this.supplier.Email = data._Email;
        this.supplier.Address = data._Address;
        this.supplier.Last_Updated = data._Last_Updated;
        this.supplier.Created_Date = data._Created_Date;
        this.supplier.Updated_By = data._Updated_By;
        this.supplier.Created_By = data._Created_By;
        this.supplier.Person_In_Charge = data._Person_In_Charge;
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.supplier);
  }

  emailFlag= false;
  phoneFlag= false;

  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  phoneChange(event,model:NgModel){

    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

}
