import { POStock } from './../Object_Classes/Part/Part';
import { AngularFireStorage } from '@angular/fire/storage';
import { RawMaterialInfo } from './../Object_Classes/RawMaterial/RawMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../Object_Classes/Part/Part';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';


export class PartDB_controller {
  constructor(private db: AngularFireDatabase,private storage:AngularFireStorage, private firestore: AngularFirestore) {
  }
  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getPartList(): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');

    var snapshotPO = await this.db.database.ref('Purchase Order').once('value');


    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let part = new Part();
        part.Part_No = childSnapshot.key;
        part.Cycle_Time = childSnapshot.child('Cycle Time').val();
        part.Available_Machine = childSnapshot.child('Available Machine').val();
        part.Part_Name = childSnapshot.child('Part Description').val();
        part.Part_Price = childSnapshot.child('PPL CUS').val();
        part.Part_Material = childSnapshot.child('Part Material').val();
        part.Part_Width = childSnapshot.child('Part Width').val();
        part.Part_Length = childSnapshot.child('Part Length').val();
        part.Part_Thickness = childSnapshot.child('Part Thickness').val();
        part.Part_Gusset = childSnapshot.child('Part Gusset').val();
        part.widthUnit = childSnapshot.child('Part Width Unit').val();
        part.lengthUnit = childSnapshot.child('Part Length Unit').val();
        part.thicknessUnit = childSnapshot.child('Part Thickness Unit').val();
        part.gussetUnit = childSnapshot.child('Part Gusset Unit').val();
        part.Color = childSnapshot.child('Part Color').val();
        part.Front_Printing = childSnapshot.child('Front Printing').val();
        part.Back_Printing = childSnapshot.child('Back Printing').val();
        part.Flip = childSnapshot.child('Flip').val();
        part.Air_Gaps = childSnapshot.child('Air Gaps').val();
        part.Holes = childSnapshot.child('Holes').val();
        part.Cutting = childSnapshot.child('Cutting').val();
        part.Part_Unit = childSnapshot.child('Part Unit').val();
        part.Counter_Per_Kg = childSnapshot.child('Counter Per Tubing').val();
        part.Weight_Per_Kg = childSnapshot.child('Weight Per Tubing').val();
        part.Stock_Quantity = parseFloat(childSnapshot.child('Stock Quantity').val());
        part.Customer_Name = childSnapshot.child('Customer Preference/Customer Name').val();
        part.Remarks = childSnapshot.child('Customer Preference/Remarks').val();
        part.Material = childSnapshot.child('Customer Preference/Material').val();
        part.PO_No = childSnapshot.child('Customer Preference/PO No').val();
        part.Box_Size = childSnapshot.child('Packaging/Box/Box Size').val();
        part.Box_Quantity = childSnapshot.child('Packaging/Box/Quantity in Box').val();
        part.Sec_Pro_1_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro1_CycleTime').val();
        part.PPL_CUS = childSnapshot.child('Customer Preference/PPL CUS').val();
        part.Sec_Cycle_Time = childSnapshot.child('SEC Cycle time').val();
        part.customerID = childSnapshot.child('CustomerID').val();

        part.BagType = childSnapshot.child('BagType').val();
        part.SmallPacking = childSnapshot.child('SmallPacking').val();
        part.UOM_SmallPacking = childSnapshot.child('UOM_SmallPacking').val();
        part.BagTypePacking = childSnapshot.child('BagTypePacking').val();
        part.UOM_BagTypePacking = childSnapshot.child('UOM_BagTypePacking').val();

        part.CuttingCycleTime = childSnapshot.child('Cutting Cycle Time').val();
        part.ExtrusionCycleTime = childSnapshot.child('Extrusion Cycle Time').val();
        part.ExtraInfo = childSnapshot.child('ExtraInfo').val();


        let stock = 0;

        childSnapshot.child('Stock').forEach(e=>{
          part.Stock.push({
            key: e.key,
            val: e.val()
          })
          stock += parseFloat(e.val());
          this.db.database.ref('QC/'+e.key.split('@@')[0]).once('value').then(qc=>{
            if(qc.exists()){
              let temp =[]
              qc.forEach(child=>{
                if(child.key === part.Part_No){
                  if(child.child('Weighting').val()['RackingNo']){
                    if(temp.length > 0){
                      const exist = temp.findIndex(t=> t.WarehouseLocation === child.child('WarehouseLocation').val() &&  t.RackingNo === child.child('RackingNo').val());
                      if(exist === -1){
                        part.rackingNumber += 'WL:' +child.child('Weighting').val()['WarehouseLocation'] +' Rack:' +child.child('Weighting').val()['RackingNo']+'<br>';
                        temp.push({
                          WarehouseLocation:child.child('Weighting').val()['WarehouseLocation'],
                          RackingNo:child.child('Weighting').val()['RackingNo'],
                        })
                      }
                    }else{
                      part.rackingNumber += 'WL:' +child.child('Weighting').val()['WarehouseLocation'] +' Rack:' +child.child('Weighting').val()['RackingNo']+'<br>';
                      temp.push({
                        WarehouseLocation:child.child('Weighting').val()['WarehouseLocation'],
                        RackingNo:child.child('Weighting').val()['RackingNo'],
                      })
                    }
                  }

                }
              })
            }
          })
        })
        if(part.Stock_Quantity !== stock){
          if(!isNaN(stock)){
            this.db.database.ref('Part/'+part.Part_No + '/Stock Quantity').set(stock);
            part.Stock_Quantity = stock;
          }
        }

        snapshotPO.forEach(e=>{
          e.child("Part List").forEach(e2=>{
            if(part.Part_No ===e2.child('Part No').val() && e2.child('Status').val() !== 'Shipped'){
              e2.child("PO_Stock").forEach(reserve=>{
                const info ={
                  id:reserve.key,
                  rackingNo:reserve.key.split('@@')[2] || '',
                  warehouseLocation:reserve.key.split('@@')[3] || '',
                  value: parseFloat(reserve.val()),
                }
                part.Reserved_Stock += info.value;
                part.ReservedInfo.push(info);
              })
            }
          })
        })

        if (part.customerID)
          this.getCustomer(part, part.customerID)

        const rawMaterialID = [];
        childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
          const info = {
            ID: childSnapshot2.child('ID').val(),
            Name: childSnapshot2.child('Name').val(),
            Amount: childSnapshot2.child('Ratio').val(),
          };
          this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
            if (snap.exists()) {
              const raw = new RawMaterialInfo();
              raw.Material_ID = snap.key;
              raw.Material_Name = snap.child('_Material_Name').val();
              raw.Material_Color = snap.child('_Material_Color').val();
              raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
              raw.In_Stock = snap.child('_In_Stock').val();
              raw.Unit = snap.child('_Unit').val();
              raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
              raw.Updated_By = snap.child('_Updated_By').val();
              raw.Created_By = snap.child('_Created_By').val();
              raw.Created_Date = new Date(snap.child('_Created_Date').val());
              raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
              raw.Part_Material_Name = info.Name;
              raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
              raw.Raw_Type = snap.child('_Raw_Type').val();
              raw.Raw_Amount = info.Amount;
              rawMaterialID.push(raw);
              }
            });
          part.Raw_Material = rawMaterialID;
        });
        PartList.push(part);
      });
    }


    return PartList;
  }

  async add_or_update_Part(_newPart: Part) {
    var updates = {}
    await this.db.database.ref('Part/'+_newPart.Part_No).set(null).then(async r => {
      const partinfo = { }

      if(_newPart.Stock_Quantity){
        partinfo['Stock Quantity'] = _newPart.Stock_Quantity;
      }
      else{
        partinfo['Stock Quantity'] = 0;

      }
      partinfo['ExtraInfo'] = _newPart.ExtraInfo || '-';
      partinfo['Customer License'] = _newPart.Customer_License || '-';
      partinfo['Counter Per Tubing'] = _newPart.Counter_Per_Kg || '0';
      partinfo['Weight Per Tubing'] = _newPart.Weight_Per_Kg || '0';
      partinfo['Cutting Cycle Time'] = _newPart.CuttingCycleTime || '0';
      partinfo['Extrusion Cycle Time'] = _newPart.ExtrusionCycleTime || '0';
      partinfo['Available Machine'] = _newPart.Available_Machine || '-';
      partinfo['Part Description'] = _newPart.Part_Name || '-';
      partinfo['Part Material'] = _newPart.Part_Material || '-';
      partinfo['Part Width'] = _newPart.Part_Width || '-';
      partinfo['Part Width Unit'] = _newPart.widthUnit || '-';
      partinfo['Part Length'] = _newPart.Part_Length || '-';
      partinfo['Part Length Unit'] = _newPart.lengthUnit || '-';
      partinfo['Part Thickness'] = _newPart.Part_Thickness || '-';
      partinfo['Part Thickness Unit'] = _newPart.thicknessUnit || '-';
      partinfo['Part Gusset'] = _newPart.Part_Gusset || '-';
      partinfo['Part Gusset Unit'] = _newPart.gussetUnit || '-';
      partinfo['Part Color'] = _newPart.Color || '-';
      partinfo['Front Printing'] = _newPart.Front_Printing || '-';
      partinfo['Back Printing'] = _newPart.Back_Printing || '-';
      partinfo['Flip'] = _newPart.Flip || '-';
      partinfo['Air Gaps'] = _newPart.Air_Gaps || '-';
      partinfo['Holes'] = _newPart.Holes || '-';
      partinfo['Cutting'] = _newPart.Cutting || '-';

      partinfo['BagType'] = _newPart.BagType || '-';
      partinfo['SmallPacking'] = _newPart.SmallPacking || '-';
      partinfo['UOM_SmallPacking'] = _newPart.UOM_SmallPacking || '-';
      partinfo['BagTypePacking'] = _newPart.BagTypePacking || '-';
      partinfo['UOM_BagTypePacking'] = _newPart.UOM_BagTypePacking || '-';


      // partinfo['Part Unit'] = _newPart.Part_Unit || '-';
      // partinfo['SEC Cycle time'] = _newPart.Sec_Cycle_Time || '0';
      // partinfo['CustomerID'] = _newPart.customerID || '-';

      await this.db.database.ref('Part/' + _newPart.Part_No).set(partinfo)

      for (const stock of _newPart.Stock) {
        await this.db.database.ref('Part/' + _newPart.Part_No + '/Stock/' + stock.key).set(stock.val)
      }

      const custinfo ={}

      // custinfo['Customer Name'] = _newPart.Customer_Name || '-';
      // custinfo['PO No'] = _newPart.PO_No || '-';
      // custinfo['PPL CUS'] = _newPart.PPL_CUS || '-';
      // custinfo['Remarks'] = _newPart.Remarks || '-';
      // await this.db.database.ref('Part/' + _newPart.Part_No +'/Customer Preference').set(custinfo);

      // const packInfo ={}

      // packInfo['Box Size'] = _newPart.Box_Size || '-';
      // packInfo['Quantity in Box'] = _newPart.Box_Quantity || '-';
      // await this.db.database.ref('Part/' + _newPart.Part_No +'/Packaging/Box').set(packInfo)

      // const secInfo ={}

      // secInfo['Sec_Pro1_CycleTime'] = _newPart.Sec_Pro_1_Cycle_Time || '-';

      // await this.db.database.ref('Part/' + _newPart.Part_No +'/Secondary Process').set(secInfo)

      _newPart.Raw_Material.forEach(async (r, index) => {
        const value = {
          ID: r.Material_ID,
          Name: r.Part_Material_Name,
          Type: r.Raw_Type,
          Ratio: r.Raw_Amount,
        }
        await this.db.database.ref('Part/' + _newPart.Part_No+ '/Raw Material/raw' + index).set(value)
      })


      const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
      const info = {
        date: new Date(),
        content:JSON.stringify(_newPart),
      };
      await this.firestore.collection('PartLog').doc(dateFormat).set({ Date: new Date() });
      await this.firestore.collection('PartLog').doc(dateFormat).collection('Part').add(info);

    })
  }

  async search_Part(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var childSnapshot = await this.db.database.ref('Part/'+Part_No).once('value');

    if (childSnapshot.exists()) {

        var get_Part_No = childSnapshot.key;
        if (Part_No.match(get_Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Customer_License = childSnapshot.child('Customer License').val();
          searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
          searched_part.DoP_Update = new Date(childSnapshot.child('Date of Price Update').val());

          searched_part.DoR = new Date(childSnapshot.child('Date of Revised').val());

          searched_part.Duty_Export = childSnapshot.child('Duty Export').val();
          searched_part.FG_or_Semi = childSnapshot.child('FG_or_Semi').val();
          searched_part.Machine_Tonnage = childSnapshot.child('Machine Tonnage').val();
          searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
          searched_part.Number_Cavity = childSnapshot.child('Number of Cavity').val();
          searched_part.Part_Name = childSnapshot.child('Part Description').val();
          searched_part.Part_Price = childSnapshot.child('PPL CUS').val();
          searched_part.Part_Material = childSnapshot.child('Part Material').val();
          searched_part.Part_Runner_Weight = childSnapshot.child('Part Runner Weight').val();
          searched_part.Part_Width = childSnapshot.child('Part Width').val();
          searched_part.Part_Length = childSnapshot.child('Part Length').val();
          searched_part.Part_Thickness = childSnapshot.child('Part Thickness').val();
          searched_part.Part_Gusset = childSnapshot.child('Part Gusset').val();
          searched_part.widthUnit = childSnapshot.child('Part Width Unit').val();
          searched_part.lengthUnit = childSnapshot.child('Part Length Unit').val();
          searched_part.thicknessUnit = childSnapshot.child('Part Thickness Unit').val();
          searched_part.gussetUnit = childSnapshot.child('Part Gusset Unit').val();
          searched_part.Front_Printing = childSnapshot.child('Front Printing').val();
          searched_part.Back_Printing = childSnapshot.child('Back Printing').val();
          searched_part.Flip = childSnapshot.child('Flip').val();
          searched_part.Air_Gaps = childSnapshot.child('Air Gaps').val();
          searched_part.Holes = childSnapshot.child('Holes').val();
          searched_part.Cutting = childSnapshot.child('Cutting').val();
          searched_part.Counter_Per_Kg = childSnapshot.child('Counter Per Tubing').val();
          searched_part.Weight_Per_Kg = childSnapshot.child('Weight Per Tubing').val();

          searched_part.Project_Start_Date = new Date(childSnapshot.child('Project Start Date').val());

          searched_part.Project_Status = childSnapshot.child('Project Status').val();
          searched_part.Remarks = childSnapshot.child('Remarks').val();
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          searched_part.Traffic_Code = childSnapshot.child('Traffic Code').val();
          searched_part.Traffic_Code_Name = childSnapshot.child('Traffic Code Name').val();
          searched_part.Color = childSnapshot.child('Part Color').val();

          searched_part.Customer_Name = childSnapshot.child('Customer Preference/Customer Name').val();
          searched_part.Material = childSnapshot.child('Customer Preference/Material').val();
          searched_part.PO_No = childSnapshot.child('Customer Preference/PO No').val();

          searched_part.Mould_Family = childSnapshot.child('Mould/Mould Family Info').val();
          searched_part.Mould_Info = childSnapshot.child('Mould/Mould Information').val();
          searched_part.Mould_Material = childSnapshot.child('Mould/Mould Macterial Used').val();
          searched_part.Mould_Maker_Name = childSnapshot.child('Mould/Mould Maker Name').val();
          searched_part.Mould_Rack_Location = childSnapshot.child('Mould/Mould Rack Location').val();
          searched_part.Mould_Price = childSnapshot.child('PPL MOLD').val();
          searched_part.Mould_Reference_No = childSnapshot.child('Mould/Mould Referance No').val();
          searched_part.Mould_Size = childSnapshot.child('Mould/Mould Size').val();

          searched_part.Box_Size = childSnapshot.child('Packaging/Box/Box Size').val();
          searched_part.Box_Quantity = childSnapshot.child('Packaging/Box/Quantity in Box').val();
          searched_part.Polybag_Size = childSnapshot.child('Packaging/Poly Bag/Bag Size').val();
          searched_part.Polybag_Quantity = childSnapshot.child('Packaging/Poly Bag/Quantity in Bag').val();

          searched_part.Sec_Pro_1 = childSnapshot.child('Secondary Process/Sec_Pro1').val();
          searched_part.Sec_Pro_1_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro1_CycleTime').val();
          searched_part.Sec_Pro_2 = childSnapshot.child('Secondary Process/Sec_Pro2').val();
          searched_part.Sec_Pro_2_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro2_CycleTime').val();

          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();

          searched_part.Model = childSnapshot.child('Model').val();
          searched_part.PPL_CUS = childSnapshot.child('PPL CUS').val();
          searched_part.PPL_MOLD = childSnapshot.child('PPL MOLD').val();
          searched_part.supplier1ID = childSnapshot.child('Supplier1ID').val();
          searched_part.supplier4ID = childSnapshot.child('Supplier4ID').val();
          searched_part.supplier5ID = childSnapshot.child('Supplier5ID').val();
          searched_part.supplier6ID = childSnapshot.child('Supplier6ID').val();
          searched_part.Usage1 = childSnapshot.child('Usage1').val();
          searched_part.Usage2 = childSnapshot.child('Usage2').val();
          searched_part.PhotoURL = childSnapshot.child('PhotoURL').val();
          searched_part.Sec_Cycle_Time = childSnapshot.child('SEC Cycle time').val();
          searched_part.DrawingURL = childSnapshot.child('DrawingURL').val();
          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();
          searched_part.Customer_Address = childSnapshot.child('Customer Address').val();
          searched_part.customerID = childSnapshot.child('CustomerID').val();

          searched_part.BagType = childSnapshot.child('BagType').val();
          searched_part.SmallPacking = childSnapshot.child('SmallPacking').val();
          searched_part.UOM_SmallPacking = childSnapshot.child('UOM_SmallPacking').val();
          searched_part.BagTypePacking = childSnapshot.child('BagTypePacking').val();
          searched_part.UOM_BagTypePacking = childSnapshot.child('UOM_BagTypePacking').val();

          searched_part.CuttingCycleTime = childSnapshot.child('Cutting Cycle Time').val();
          searched_part.ExtrusionCycleTime = childSnapshot.child('Extrusion Cycle Time').val();



          // this.getCustomer(searched_part, searched_part.customerID);
          // this.getSupplier(searched_part, searched_part.supplier1ID, 1);
          // this.getSupplier(searched_part, searched_part.supplier4ID, 4);
          // this.getSupplier(searched_part, searched_part.supplier5ID, 5);
          // this.getSupplier(searched_part, searched_part.supplier6ID, 6);

          // this.getMaterial(searched_part.Inner_BoxSize).then(data=>{
          //   searched_part.InnerBox = data;
          // })
          // this.getMaterial(searched_part.Polybag_Size).then(data=>{
          //   searched_part.PolyBag = data;
          // })
          // this.getMaterial(searched_part.Box_Size).then(data=>{
          //   searched_part.CartonBox = data;
          // })
          searched_part.MaterialBatch = [];
          searched_part.Pigment = [];

          const rawMaterialID = [];

          childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
            const info = {
              ID: childSnapshot2.child('ID').val(),
              Name: childSnapshot2.child('Name').val(),
              CustomerMaterial: childSnapshot2.child('CustomerMaterial').val(),
              SpenMaterial: childSnapshot2.child('SpenMaterial').val(),
              CustomerRal: childSnapshot2.child('CustomerRal').val(),
              Ratio: childSnapshot2.child('Ratio').val(),
              supplier: childSnapshot2.child('Supplier').val(),
              supplierID: childSnapshot2.child('SupplierID').val(),
              Amount: childSnapshot2.child('Ratio').val(),
              supplierCurrency: '',
            }

            if(info.ID){
              this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                if (snap.exists()) {
                  const raw = new RawMaterialInfo();

                  raw.Material_ID = snap.key;
                  raw.Material_Name = snap.child('_Material_Name').val();
                  raw.Material_Color = snap.child('_Material_Color').val();
                  raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                  raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                  raw.Material_Spen = snap.child('_Material_Spen').val();
                  raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                  raw.In_Stock = snap.child('_In_Stock').val();
                  raw.Unit = snap.child('_Unit').val();
                  raw.Unit_Price = snap.child('_Unit_Price').val();
                  raw.In_Stock = snap.child('_In_Stock').val();
                  raw.Updated_By = snap.child('_Updated_By').val();
                  raw.Created_By = snap.child('_Created_By').val();
                  raw.Created_Date = new Date(snap.child('_Created_Date').val());
                  raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                  raw.Part_Material_Name = info.Name;
                  raw.Customer_Material = info.CustomerMaterial;
                  raw.SPEN_Material = info.SpenMaterial;
                  raw.Customer_RAL = info.CustomerRal;
                  raw.supplierCurrency =info.supplierCurrency
                  raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                  raw.Mat_Ratio = info.Ratio;
                  raw.Supplier = info.supplier;
                  raw.supplierID = info.supplierID;
                  raw.Raw_Amount = info.Amount;
                  raw.Raw_Type = snap.child('_Raw_Type').val();
                  rawMaterialID.push(raw);
                }
              });
            }

            /*if(info.supplierID != null && info.supplierID != ''){
              this.db.database.ref('Supplier').child(info.supplierID).once('value').then(snapshot=>{
                if(snapshot.exists()){
                  info.supplierCurrency = snapshot.child('_Currency').val();
                  info.supplier = snapshot.child('_Supplier_Name').val();
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Amount = info.Amount;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }
                else{
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Raw_Amount = info.Amount;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }
              }).catch(error=>{
                info.supplier = null;
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                        raw.Material_ID = snap.key;
                        raw.Material_Name = snap.child('_Material_Name').val();
                        raw.Material_Color = snap.child('_Material_Color').val();
                        raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                        raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                        raw.Material_Spen = snap.child('_Material_Spen').val();
                        raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                        raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Unit = snap.child('_Unit').val();
                        raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Updated_By = snap.child('_Updated_By').val();
                        raw.Created_By = snap.child('_Created_By').val();
                        raw.Created_Date = new Date(snap.child('_Created_Date').val());
                        raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                        raw.Part_Material_Name = info.Name;
                        raw.Customer_Material = info.CustomerMaterial;
                        raw.SPEN_Material = info.SpenMaterial;
                        raw.Customer_RAL = info.CustomerRal;
                        raw.supplierCurrency =info.supplierCurrency
                        raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                        raw.Mat_Ratio = info.Ratio;
                        raw.Raw_Amount = info.Amount;
                        raw.Supplier = info.supplier;
                        raw.supplierID = info.supplierID;
                        raw.Raw_Type = snap.child('_Raw_Type').val();
                        rawMaterialID.push(raw);
                      }
                });
              })
            }*/
          });
          searched_part.Raw_Material = rawMaterialID;
        }

    }


    return searched_part;
  }

  async search_Partial_Part(Part_No: string): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_part = new Part();
        var get_Part_No = childSnapshot.key;
        if (get_Part_No.includes(Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Customer_License = childSnapshot.child('Customer License').val();
          searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
          searched_part.DoP_Update = new Date(childSnapshot.child('Date of Price Update').val());

          searched_part.DoR = new Date(childSnapshot.child('Date of Revised').val());

          searched_part.Duty_Export = childSnapshot.child('Duty Export').val();
          searched_part.FG_or_Semi = childSnapshot.child('FG_or_Semi').val();
          searched_part.Machine_Tonnage = childSnapshot.child('Machine Tonnage').val();
          searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
          searched_part.Number_Cavity = childSnapshot.child('Number of Cavity').val();
          searched_part.Part_Name = childSnapshot.child('Part Description').val();
          searched_part.Part_Price = childSnapshot.child('PPL CUS').val();

          searched_part.Part_Runner_Weight = childSnapshot.child('Part Runner Weight').val();
          searched_part.Part_Weight = childSnapshot.child('Part Weight').val();
          searched_part.Project_Start_Date = new Date(childSnapshot.child('Project Start Date').val());

          searched_part.Project_Status = childSnapshot.child('Project Status').val();
          searched_part.Remarks = childSnapshot.child('Remarks').val();
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          searched_part.Traffic_Code = childSnapshot.child('Traffic Code').val();
          searched_part.Traffic_Code_Name = childSnapshot.child('Traffic Code Name').val();

          searched_part.Color = childSnapshot.child('Customer Preference/Color').val();
          searched_part.Customer_Name = childSnapshot.child('Customer Preference/Customer Name').val();
          searched_part.Material = childSnapshot.child('Customer Preference/Material').val();
          searched_part.PO_No = childSnapshot.child('Customer Preference/PO No').val();

          searched_part.Mould_Family = childSnapshot.child('Mould/Mould Family Info').val();
          searched_part.Mould_Info = childSnapshot.child('Mould/Mould Information').val();
          searched_part.Mould_Material = childSnapshot.child('Mould/Mould Macterial Used').val();
          searched_part.Mould_Maker_Name = childSnapshot.child('Mould/Mould Maker Name').val();
          searched_part.Mould_Rack_Location = childSnapshot.child('Mould/Mould Rack Location').val();
          searched_part.Mould_Price = childSnapshot.child('PPL MOLD').val();

          searched_part.Mould_Reference_No = childSnapshot.child('Mould/Mould Referance No').val();
          searched_part.Mould_Size = childSnapshot.child('Mould/Mould Size').val();

          searched_part.Box_Size = childSnapshot.child('Packaging/Box/Box Size').val();
          searched_part.Box_Quantity = childSnapshot.child('Packaging/Box/Quantity in Box').val();
          searched_part.Polybag_Size = childSnapshot.child('Packaging/Poly Bag/Bag Size').val();
          searched_part.Polybag_Quantity = childSnapshot.child('Packaging/Poly Bag/Quantity in Bag').val();

          searched_part.Sec_Pro_1 = childSnapshot.child('Secondary Process/Sec_Pro1').val();
          searched_part.Sec_Pro_1_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro1_CycleTime').val();
          searched_part.Sec_Pro_2 = childSnapshot.child('Secondary Process/Sec_Pro2').val();
          searched_part.Sec_Pro_2_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro2_CycleTime').val();
          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();

          searched_part.Model = childSnapshot.child('Model').val();
          searched_part.PPL_CUS = childSnapshot.child('PPL CUS').val();
          searched_part.PPL_MOLD = childSnapshot.child('PPL MOLD').val();
          searched_part.supplier1ID = childSnapshot.child('Supplier1ID').val();
          searched_part.supplier4ID = childSnapshot.child('Supplier4ID').val();
          searched_part.supplier5ID = childSnapshot.child('Supplier5ID').val();
          searched_part.supplier6ID = childSnapshot.child('Supplier6ID').val();
          searched_part.Usage1 = childSnapshot.child('Usage1').val();
          searched_part.Usage2 = childSnapshot.child('Usage2').val();
          searched_part.PhotoURL = childSnapshot.child('PhotoURL').val();
          searched_part.Sec_Cycle_Time = childSnapshot.child('SEC Cycle time').val();
          searched_part.DrawingURL = childSnapshot.child('DrawingURL').val();
          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();
          searched_part.Customer_Address = childSnapshot.child('Customer Address').val();
          searched_part.customerID = childSnapshot.child('CustomerID').val();
          this.getCustomer(searched_part, searched_part.customerID);

          this.getSupplier(searched_part, searched_part.supplier1ID, 1);
          this.getSupplier(searched_part, searched_part.supplier4ID, 4);
          this.getSupplier(searched_part, searched_part.supplier5ID, 5);
          this.getSupplier(searched_part, searched_part.supplier6ID, 6);
          this.getMaterial(searched_part.Inner_BoxSize).then(data=>{
            searched_part.InnerBox = data;
          })
          this.getMaterial(searched_part.Polybag_Size).then(data=>{
            searched_part.PolyBag = data;
          })
          this.getMaterial(searched_part.Box_Size).then(data=>{
            searched_part.CartonBox = data;
          })
          searched_part.MaterialBatch = [];
          searched_part.Pigment = [];

          const rawMaterialID = [];
          childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
            const info = {
              ID: childSnapshot2.child('ID').val(),
              Name: childSnapshot2.child('Name').val(),
              CustomerMaterial: childSnapshot2.child('CustomerMaterial').val(),
              SpenMaterial: childSnapshot2.child('SpenMaterial').val(),
              CustomerRal: childSnapshot2.child('CustomerRal').val(),
              Ratio: childSnapshot2.child('Ratio').val(),
              supplier: childSnapshot2.child('Supplier').val(),
              supplierID: childSnapshot2.child('SupplierID').val(),
              supplierCurrency: '',
            }
            if(info.supplierID){
              this.db.database.ref('Supplier').child(info.supplierID).once('value').then(snapshot=>{
                if(snapshot.exists()){
                  info.supplierCurrency = snapshot.child('_Currency').val();
                  info.supplier = snapshot.child('_Supplier_Name').val();
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }else{
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }
              }).catch(error=>{
                info.supplier = null;
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                        raw.Material_ID = snap.key;
                        raw.Material_Name = snap.child('_Material_Name').val();
                        raw.Material_Color = snap.child('_Material_Color').val();
                        raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                        raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                        raw.Material_Spen = snap.child('_Material_Spen').val();
                        raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                        raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Unit = snap.child('_Unit').val();
                        raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Updated_By = snap.child('_Updated_By').val();
                        raw.Created_By = snap.child('_Created_By').val();
                        raw.Created_Date = new Date(snap.child('_Created_Date').val());
                        raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                        raw.Part_Material_Name = info.Name;
                        raw.Customer_Material = info.CustomerMaterial;
                        raw.SPEN_Material = info.SpenMaterial;
                        raw.Customer_RAL = info.CustomerRal;
                        raw.supplierCurrency =info.supplierCurrency
                        raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                        raw.Mat_Ratio = info.Ratio;
                        raw.Supplier = info.supplier;
                        raw.supplierID = info.supplierID;
                        raw.Raw_Type = snap.child('_Raw_Type').val();
                        rawMaterialID.push(raw);
                      }
                });
              })
            }
          });
          searched_part.Raw_Material = rawMaterialID;
          PartList.push(searched_part);
        }
      }));
    }

    return PartList;
  }


  delete_Part(part_no: string) {
    this.db.database.ref('/Part/' + part_no).set(null);
  }


  async getMaterial(id): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('RawMaterial').child(id).once('value');
    if (snapshot.exists()) {
        searched_raw.Material_ID = snapshot.key;
        searched_raw.Material_Name = snapshot.child('_Material_Name').val();
        searched_raw.Material_Color = snapshot.child('_Material_Color').val();
        searched_raw.Material_Color_Code = snapshot.child('_Material_Color_Code').val();
        searched_raw.Material_Color_Ratio = snapshot.child('_Material_Color_Ratio').val();
        searched_raw.Material_Spen = snapshot.child('_Material_Spen').val();
        searched_raw.In_Stock = snapshot.child('_In_Stock').val();
        searched_raw.Quantity_Reserved = snapshot.child('_Quantity_Reserved').val();
        searched_raw.Unit = snapshot.child('_Unit').val();
        searched_raw.Quantity_PerBag = snapshot.child('_Quantity_PerBag').val();
        searched_raw.Unit_Price = snapshot.child('_Unit_Price').val();
        searched_raw.Updated_By = snapshot.child('_Updated_By').val();
        searched_raw.Created_By = snapshot.child('_Created_By').val();
        searched_raw.Created_Date = new Date(snapshot.child('_Created_Date').val());
        searched_raw.Last_Updated = new Date(snapshot.child('_Last_Updated').val());
        searched_raw.Raw_Type = snapshot.child('_Raw_Type').val();
        searched_raw.Carton_Weight = snapshot.child('_Carton_Weight').val();
    }
    return searched_raw;
  }

  async getSupplier(part:Part, supplierId, number): Promise<void>{
    var snapshot = await this.db.database.ref('Supplier').child(supplierId).once('value');
    if(snapshot.exists()){
      switch(number){
        case 1:part.Supplier1 = snapshot.child('_Supplier_Name').val();break;
        case 4:part.Supplier4 = snapshot.child('_Supplier_Name').val();break;
        case 5:part.Supplier5 = snapshot.child('_Supplier_Name').val();break;
        case 6:part.Supplier6 = snapshot.child('_Supplier_Name').val();break;
      }
    }
  }

  async getCustomer(part:Part, customerId): Promise<void>{
    var snapshot = await this.db.database.ref('Customer').child(customerId).once('value');
    if(snapshot.exists()){
      part.Customer_Name = snapshot.child('_Customer_Name').val();
      part.Customer_Address = snapshot.child('_Address').val();
    }
  }

  async search_PartWithStock(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( childSnapshot=>{
        var get_Part_No = childSnapshot.key;
        if (Part_No.match(get_Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          const postocks : POStock[] = [];
          childSnapshot.child('Stock').forEach(child=>{
            const stock = new POStock();
            stock.BoxNumber = child.key;
            stock.Quantity = child.val();
            postocks.push(stock)
          })
          searched_part.POStocks = postocks;
        }
      });
    }

    return searched_part;
  }


}
