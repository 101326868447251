import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { async } from '@angular/core/testing';
import { RawDB_controller } from './../../Services/DB_Controller/RawDB_controller';
import { RawMaterialInfo } from './../../Services/Object_Classes/RawMaterial/RawMaterial';
import { SupplierDB_controller } from './../../Services/DB_Controller/SupplierDB_controller';
import { Supplier } from './../../Services/Object_Classes/RawMaterial/Supplier';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { MachineDB_controller } from './../../Services/DB_Controller/MachineDB_controller';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import Chart from 'chart.js';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgZone } from '@angular/core';
import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { OEEHistoryComponent } from './oee-history/oee-history.component';
import * as XLSX from 'xlsx'
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { Tubing, TubingInfo } from 'src/app/Services/Object_Classes/Tubing/Tubing';
import { JobTubingDetailsComponent } from '../purchaseOrder/job-tubing-details/job-tubing-details.component';
import { PartServices } from 'src/app/Services/Utilities/part.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  InterruptCodeList = [
    {
      value: 'NJ',
      ViewValue: 'No Job Order'
    },
    {
      value: 'MUM',
      ViewValue: 'Machine Under Maintenance'
    },
    {
      value: 'NP',
      ViewValue: 'Nozzle Problem'
    },
    {
      value: 'HP',
      ViewValue: 'Hopper Problem'
    },
    {
      value: 'BP',
      ViewValue: 'Barrel Problem'
    },
    {
      value: 'WL',
      ViewValue: 'Water/Oil Leaking'
    },
    {
      value: 'MT',
      ViewValue: 'Mould Testing'
    },
    {
      value: 'MS',
      ViewValue: 'Mould Services'
    },
    {
      value: 'PB',
      ViewValue: 'Pin Broken'
    },
    {
      value: 'NBP',
      ViewValue: 'Nylon Bush Problem'
    },
    {
      value: 'DC',
      ViewValue: 'Double Clamp'
    },
    {
      value: 'NM',
      ViewValue: 'No Material'
    },
    {
      value: 'MUP',
      ViewValue: 'Material Under Preheat'
    },
    {
      value: 'SM',
      ViewValue: 'Sink Mark'
    },
    {
      value: 'SMD',
      ViewValue: 'Short Moulding'
    },
    {
      value: 'RB',
      ViewValue: 'Runner/Gate Broken'
    },
    {
      value: 'MM',
      ViewValue: 'Moisture Mark'
    },
    {
      value: 'DO',
      ViewValue: 'Dimension Out'
    }, {
      value: 'CO',
      ViewValue: 'Colour Out'
    }, {
      value: 'MP',
      ViewValue: 'Machine Problem'
    }, {
      value: 'OTHS',
      ViewValue: 'Others'
    },
    {
      value: 'RP',
      ViewValue: 'Robot Problem'
    },
    {
      value: 'BRK',
      ViewValue: 'Break Time'
    },
    {
      value: 'CS',
      ViewValue: 'Change Shift'
    }]
  machineDbControler: MachineDB_controller = new MachineDB_controller(this.db)
  MachineList: Machine[] = [];
  jobOEE: number[] = [];
  monthOEE: number[] = [];
  noJobHours: number[] = [];
  partlist = [];

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private toast: ToastrService,
    private zone: NgZone,
    private changeDef: ChangeDetectorRef,
    private partSrv: PartServices,

  ) {

   }
  ngOnInit() {
    this.setup();

  }

  getTime(value){
    return (new Date( new Date().getTime() + value * 60 * 60000))
  }

  customer: Customer[] = []
  supplier: Supplier[] = []
  rawmaterial: RawMaterialInfo[] = []




  setup() {
    this.spinner.show();

    this.db.database.ref('Machine').on('value', async (DataSnapshot) => {
      this.MachineList = await this.machineDbControler.getMachineList(DataSnapshot);
      this.MachineList = this.MachineList.sort((a, b) => {
        return (a.Brand < b.Brand ? -1 : 1);
      })

      for (const element of this.MachineList) {

        element.Schedule_Track = this.getSortedScheduleList(element.Schedule_Track);

        if (element.Schedule_Track.length > 0) {

          element.Schedule_Track[0].tubingInfo = await this.getScheduleTubingInfo(element.Schedule_Track[0].Machine_Schedule_PO_No, element.Schedule_Track[0].Machine_Schedule_Part_No, element.Schedule_Track[0]);
          element.Schedule_Track[0].autotubingInfo = await this.getScheduleAutoTubingInfo(element.Schedule_Track[0].Machine_Schedule_PO_No, element.Schedule_Track[0].Machine_Schedule_Part_No);
        }
      }

      // this.changeDef.detectChanges();
      // this.changeDef.markForCheck();
      this.spinner.hide();
    });

    setTimeout(function(){this.spinner.hide();}, 3000);
  }

  getSortedScheduleList(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker[] {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }


    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }

    let currentDate = new Date();
    let previousJobList: ScheduleTracker = Schdule_TrackList[0];
    if (Schdule_TrackList.length > 0) {
      for (var i = 0; i < Schdule_TrackList.length; i++) {
        if (new Date(Schdule_TrackList[i].DateFormatEnd).getTime() <= currentDate.getTime()) {
          if (new Date(previousJobList.DateFormatEnd).getTime() <= new Date(Schdule_TrackList[i].DateFormatEnd).getTime()) {
            previousJobList = Schdule_TrackList[i];
          }
        }
      }
      this.jobOEE.push(previousJobList.Availability * previousJobList.Performance);
    } else {
      this.jobOEE.push(0);
    }

    return SortedScheduleList;
  }
  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }
      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList:ScheduleTracker[]):ScheduleTracker{
    if(Schdule_TrackList.length > 0){
      Schdule_TrackList.sort((a,b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    let InProgress_Schedule:ScheduleTracker;
    for(var i = 0; i < Schdule_TrackList.length ; i++){
        if(Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")){

          InProgress_Schedule = Schdule_TrackList[i]
          return InProgress_Schedule
        }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList:ScheduleTracker[]):ScheduleTracker{
    let Stopped_Schedule:ScheduleTracker;

    if(Schdule_TrackList.length > 0){
      Schdule_TrackList.sort((a,b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for(var i = 0; i < Schdule_TrackList.length ; i++){
        if(Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")){
          Stopped_Schedule = Schdule_TrackList[i]
          return Stopped_Schedule
        }
    }
    return null;
  }

  getColor(machine: Machine) {
    let running_schedule: ScheduleTracker = this.machineDbControler.get_CurrentRunningSchedule(machine.Schedule_Track);
    if (running_schedule) {
      if (machine.MachineStatus.match('ON') && machine.Machine_Code.match('-') && running_schedule && running_schedule.Machine_Schedule_Status.match('In Progress')) {
        return 'running';
      }
    } else {
      if (machine.MachineStatus.match('ON') && machine.Machine_Code.match('-')) {
        return 'warning';
      } else if (machine.MachineStatus.match('OFF') && machine.Machine_Code.match('-')) {
        return 'off';
      } else if (!machine.MachineStatus.match('-')) {
        return 'error';
      }
    }
  }

  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }
  getCode(value){
    let r = this.InterruptCodeList.find(i => i.value === value);
    if(r)
      return r.ViewValue;
    else
      return 'Others'
  }
  viewOEEHistory(machineNumber) {
    let cDate = new Date();
    console.log(machineNumber)
    this.MachineList.forEach(data => {
      if (data.Machine_No.match(machineNumber)) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = '80%';
        const position = {
          top: '5%'
        };
        dialogConfig.position = position;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          machineDetails: data,
        };
        this.dialog.open(OEEHistoryComponent, dialogConfig).afterClosed().subscribe(result => {
        });
      }
    })
  }

  returnNumber(value){
    return parseInt(value);
  }

  // rawD : RawDB_controller = new RawDB_controller(this.db,this.firestore)
  // parD : PartDB_controller = new PartDB_controller(this.db,this.storage, this.firestore)
  // onFileChange(event: any) {
  //   /* wire up file reader */
  //   const target: DataTransfer = <DataTransfer>(event.target);
  //   if (target.files.length !== 1) {
  //     throw new Error('Cannot use multiple files');
  //   }
  //   const reader: FileReader = new FileReader();
  //   reader.readAsBinaryString(target.files[0]);
  //   reader.onload = (e: any) => {
  //     /* create workbook */
  //     const binarystr: string = e.target.result;
  //     const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

  //     /* selected the first sheet */
  //     const wsname: string = wb.SheetNames[0];
  //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //     /* save data */
  //     const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
  //     for (const d of data) {
  //       let part : Part = new Part();
  //       if(!d['Part No'].includes('.'))
  //         this.db.database.ref('Part').child(d['Part No']).update({'Part Color':d['Colour Base']})
  //       else{
  //       part.Part_No = d['Part No'].replaceAll('.', '_')
  //             part.Part_Name = d['Description']
  //             part.ExtraInfo = d['Bag Style']
  //             part.Color = d['Colour Base']
  //             part.Part_Material = 'HM'
  //             part.Front_Printing =d['Front Printing'] == 'N/A'?'false':'true'
  //             part.Back_Printing =d['Back Printing'] == 'N/A'?'false':'true'
  //             part.Part_Width = d['Width']
  //             part.widthUnit = d['UnitW']
  //             part.Part_Length = d['Length']
  //             part.lengthUnit = d['UnitL']
  //             part.Part_Thickness = d['Thickness']
  //             part.thicknessUnit = d['UnitT']
  //             part.Part_Gusset = d['Gusset']
  //             part.gussetUnit = d['UnitG']
  //             part.Flip = '-'
  //             part.Air_Gaps = '-'
  //             part.Holes = '-'
  //             part.Cutting = '-'
  //             part.BagTypePacking = '0'
  //             part.SmallPacking = '0'
  //             part.UOM_SmallPacking = 'pcs'
  //             part.UOM_BagTypePacking = 'pcs'
  //             part.BagType = 'Bag'
  //             part.Raw_Material = [];
  //             let raw : RawMaterialInfo = new RawMaterialInfo();
  //             raw.Material_Name = 'HDPE 0961';
  //             raw.Material_ID = '4187ce0b-82ed-41ec-b244-5690efd38497'
  //             raw.Raw_Amount = '100'
  //             raw.Raw_Type = 'Resins'
  //             raw.Part_Material_Name = 'HDPE 0961'
  //             part.Raw_Material.push(raw)
  //             part.Counter_Per_Kg = d['Counter']? d['Counter']:0
  //             part.CuttingCycleTime = '1';
  //             part.ExtrusionCycleTime = '1';
  //             part.Available_Machine = '1,2,3'



  //             this.parD.add_or_update_Part(part);
  //       }


  //       console.log(d)
  //     }

  //   };
//  }

async getScheduleTubingInfo(PO_No: string, Part_No: string, schedule: ScheduleTracker): Promise<Tubing> {
  let tubing = new Tubing();
  var snapshot = await this.db.database.ref('ManualTubing/' + PO_No + '/' + Part_No).once('value');

  if (snapshot.exists()) {
    tubing.PartNumber = snapshot.key;
    tubing.PONumber = PO_No;
    tubing.ProductionQuantity = snapshot.child('ProductionQty').val();
    tubing.CreatedDate = snapshot.child('CreatedDate').val();
    tubing.UpdatedDate = snapshot.child('UpdatedDate').val();
    tubing.UpdatedBy = snapshot.child('UpdatedBy').val();
    let total = 0;

    snapshot.child('Tubings').forEach((childSnapshot2) => {
      let tubingInfo = new TubingInfo;
      tubingInfo.TubingAmount = parseFloat(childSnapshot2.val());
      tubingInfo.TimeStamp = childSnapshot2.key;
      tubing.TubingInfos.push(tubingInfo);
      total += parseInt(childSnapshot2.val());
    });

    if(tubing.TubingInfos.length > 0){
      tubing.TubingInfos.sort((a,b)=>{
        return new Date(a.TimeStamp).getTime() > new Date(b.TimeStamp).getTime()? -1 : new Date(a.TimeStamp).getTime() < new Date(b.TimeStamp).getTime() ? 1 :0
      });

      for(var i=0; i<tubing.TubingInfos.length-1; i++){
        tubing.TubingInfos[i].TubingDifferent = new Date(tubing.TubingInfos[i].TimeStamp).getTime() - new Date(tubing.TubingInfos[i+1].TimeStamp).getTime();
        tubing.TubingInfos[i].TubingDifferent = tubing.TubingInfos[i].TubingDifferent / 3600000;
      }

      tubing.TubingInfos[tubing.TubingInfos.length-1].TubingDifferent = 0;
    }

    schedule.Machine_Progress = isFinite(total/schedule.Exp_Qty)?(total/schedule.Exp_Qty*100):0;

    if(schedule.Machine_Progress > 100){
      schedule.Machine_Progress = 100
      schedule.HourLeft = 0;
    }
  }

  return tubing;
}

async getScheduleAutoTubingInfo(PO_No: string, Part_No: string): Promise<Tubing> {
  let tubing = new Tubing();
  var snapshot = await this.db.database.ref('AutoTubing/' + PO_No + '/' + Part_No).once('value');

  if (snapshot.exists()) {
    tubing.PartNumber = snapshot.key;
    tubing.PONumber = PO_No;
    tubing.ProductionQuantity = snapshot.child('ProductionQty').val();
    tubing.CreatedDate = snapshot.child('CreatedDate').val();
    tubing.UpdatedDate = snapshot.child('UpdatedDate').val();
    tubing.UpdatedBy = snapshot.child('UpdatedBy').val();

    snapshot.child('Tubings').forEach((childSnapshot2) => {
      let tubingInfo = new TubingInfo;
      tubingInfo.TubingAmount = parseFloat(childSnapshot2.val());
      tubingInfo.TimeStamp = childSnapshot2.key;
      tubing.TubingInfos.push(tubingInfo);
    });

    if(tubing.TubingInfos.length > 0){
      tubing.TubingInfos.sort((a,b)=>{
        return Number(a.TimeStamp) > Number(b.TimeStamp) ? -1 : Number(a.TimeStamp) < Number(b.TimeStamp) ? 1 :0
      });

      for(var i=0; i<tubing.TubingInfos.length-1; i++){
        tubing.TubingInfos[i].TubingDifferent = parseFloat(tubing.TubingInfos[i].TimeStamp) - parseFloat(tubing.TubingInfos[i+1].TimeStamp);
        tubing.TubingInfos[i].TubingDifferent = tubing.TubingInfos[i].TubingDifferent / 3600000;
      }

      tubing.TubingInfos[tubing.TubingInfos.length-1].TubingDifferent = 0;
    }
  }

  return tubing;
}

calculateTubing(tubing: Tubing) {
  return tubing.TubingInfos.map(a => a.TubingAmount).reduce((a:any, b:any) => { return parseFloat(a) + parseFloat(b) }, 0).toFixed(2)
}

viewTubing(job: ScheduleTracker) {
  this.dialog.open(JobTubingDetailsComponent, {
    width: '80%',
    height: 'auto',
    data: job,
    disableClose: true
  });
}
}
