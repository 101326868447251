import { Currency } from './../../../Shared/Currency';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { InvoiceDB_controller } from '../../../Services/DB_Controller/InvoiceDB_controller';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AngularFireStorage } from '@angular/fire/storage';
import { PackingListDB_controller } from '../../../Services/DB_Controller/PackingListDB_controller';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SelectPOStockComponent } from '../add-packing/select-postock/select-postock.component';
import { InfoComponent } from '../add-packing/info/info.component';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-update-packing',
  templateUrl: './update-packing.component.html',
  styleUrls: ['./update-packing.component.css']
})
export class UpdatePackingComponent implements OnInit {
  poNo = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  po_controller: PODB_controller = new PODB_controller(this.db);
  invoice_controller: InvoiceDB_controller = new InvoiceDB_controller(this.db);
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  matSelectPO: PurchaseOrder[] = [];
  matSelectPart=[];
  addForm: FormGroup;
  clone = [];
  newPO: PurchaseOrder = new PurchaseOrder();
  shippingDate = new Date();
  shipDate = [];
  searchPart: any;
  searchPO: any;
  check = false;
  check2 = false;
  selectedParts: any[] = [];
  newInvoice: Invoice = new Invoice();
  email:string;
  @ViewChild('picker', {static: true}) picker: any;
  temporaryInvoiceNum: string = "";
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  partsList: Part[] = [];
  // partUnitPrice: any[] = [];
  // partMouldPrice: any[] = [];
  // partRemark: any[] = [];
  linkPackingList: any;
  packing_controller: PackingListDB_controller = new PackingListDB_controller(this.db);
  customerList: Customer[] = [];
  customer_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  selectedCustomerName: string;
  selectedCurrency: string;
  currencyList = Currency;
  selectedSameRowInvoice: Invoice;

  public date: moment.Moment;


  searchCustomer: any;
  cloneCustomer : Customer[] = [];
  matSelectCustomer : Customer[] = [];

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  CompleteClonePO = [];
  disabled = false;
  constructor(
    private dialogRef: MatDialogRef<UpdatePackingComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private storage : AngularFireStorage,
    private firestore: AngularFirestore
  ) {
    this.linkPackingList = data.seePacking;
    if(this.linkPackingList.AddedToInvoice){
      this.disabled = true;
    }
    this.selectedSameRowInvoice = data.seeSameRowInvoice;

    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })

  }

  ngOnInit() {

    this.po_controller.search_PO_withStatusNotCompleted().then(data => {
      data.forEach(data2 => {
        data2.PO_Part_List.forEach(data3 => {
            if(this.matSelectPO.find(m=>m.PO_No === data2.PO_No)){
              return;
            }
            this.matSelectPO.push(data2);
            this.clone = this.matSelectPO.slice();
            this.CompleteClonePO = this.clone.slice();
        });
      });
    });

    this.shippingDate = new Date(this.linkPackingList.Shipping_Date);
    this.selectedCustomerName = this.linkPackingList.Destination;
    this.selectedCurrency = this.linkPackingList.Currency;

    this.setForm();
  }

  setForm(){
    const customerControl = new FormControl();
    const customerfilterControl = new FormControl();
    if(this.linkPackingList.Customer)
      customerControl.setValue(this.linkPackingList.Customer);
      customerfilterControl.valueChanges.subscribe(() => {
      this.findCustomer(customerfilterControl);
    });
    customerControl.valueChanges.subscribe(() => {
      if (customerControl.value) {
        this.searchCustomer = customerControl.value;
        this.addForm.get('destination').setValue(this.cloneCustomer.find(c=>c.Customer_Name === this.searchCustomer).Address);
      }
    });


    this.addForm = this.fb.group({
      name: '',
      customerControl: customerControl,
      customerfilterControl: customerfilterControl,
      shipdate: new Date(this.linkPackingList.PackingList_PO_List[0].PackingList_PO_Part_List[0].Shipping_Date),
      destination: this.linkPackingList.Destination,
      currency: this.linkPackingList.Currency,
      packingLists: this.fb.array([]) ,

    });
  }

  findCustomer(cus){
    if (!this.cloneCustomer) { return; }
    const search = cus.value;
    this.matSelectCustomer = this.cloneCustomer.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));

  }


  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Update this packing ? ' + this.linkPackingList.PackingList_No;

    const addPOModel = {
      PackingListName : this.addForm.get('name').value,
      ShipDate: this.addForm.get('shipdate').value,
      parts: [],
    };
    const parts = this.addForm.getRawValue().packingLists;

    let flag = false;
    parts.forEach((element, index) => {
      if (!this.selectedParts[index]){
        this.toast.error('PO information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag = true;

        return;
      }
      const info = {
        poNumber: element.poControl,
        partNumber: this.selectedParts[index].PO_Part_No,
        poQuantity: this.selectedParts[index].POQuantity,
        productionQuantity: this.selectedParts[index].PO_Part_Qty,
        remainingQuantity: (this.selectedParts[index].PO_Part_Qty - this.selectedParts[index].POQuantity).toFixed(2),
        stocks: this.selectedParts[index].Stocks
      };
      if (!info.poNumber || !info.partNumber ) {
          this.toast.error('PO information(' + (index + 1) + ') not completed!', 'Please fill in');
          flag = true;
        return;
      }
      addPOModel.parts.push(info);
    });

    if(!flag){
      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
        if (confirm) {
          this.linkPackingList.PackingList_PO_List.forEach(data => {
            data.PackingList_PO_Part_List.forEach(async data2=>{
              if(data2.isDeleted){
                await this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/').once('value').then(snapshot => {
                  snapshot.forEach(chidlsnapshot => {
                    if (chidlsnapshot.child('Part No').val() === (data2.Part_No)) {
                      this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/Added to packing').set(false);
                      this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/Take From Stocks').set(null);

                    }
                  })
                })
                if(data2.TakeFromStocks.length > 0){
                  var quantity = 0;
                  data2.TakeFromStocks.forEach(async stock=>{
                    await this.db.database.ref('Part/' + data2.Part_No).once('value').then(snapshot => {
                      if(quantity === 0)
                        quantity = snapshot.child('Stock Quantity').val();
                      let reservedQuantity = stock.DeductedQuantity;
                      let stockQuantity = parseFloat(snapshot.child('Stock/' + stock.BoxNumber + '/Quantity').val())
                      console.log(stockQuantity)
                      if(!stockQuantity)
                        stockQuantity = reservedQuantity;
                      else
                        stockQuantity +=  reservedQuantity;
                      quantity += reservedQuantity;
                      this.db.database.ref('Part/' + data2.Part_No + '/Stock Quantity').set(quantity);
                      this.db.database.ref('Part/' + data2.Part_No + '/Stock/'+ stock.BoxNumber ).set(stockQuantity);
                    })

                    this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                    '/' + data2.Part_No + '/Weighting/').once('value').then(datasnap=>{
                      var packagingQty = parseFloat(datasnap.child('MakeToStock').val());
                      var deductQty = packagingQty + stock.DeductedQuantity;
                      this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                      '/' + data2.Part_No + '/Weighting/MakeToStock').set(deductQty);
                      this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                      '/' + data2.Part_No + '/Weighting/Taken by PO/' + data.PO_No).set(null);
                    })

                  })
                }
                this.db.database.ref('PackingList/' + this.linkPackingList.PackingList_No+ '/POList/' +data.PO_No +'/' +data2.Part_No).set(null)

              }
            })
          });


          this.packing_controller.updatePackinglist(addPOModel ,this.linkPackingList.PackingList_No, this.email);
          this.dialogRef.close(true);
        }
      })
    }

  }


  updatePart(num, index) {
    this.poNo = num;
    if (this.poNo == '') {
      this.matSelectPO = [];
    }
    this.matSelectPart[index] = null;
    this.po_controller.getPOList().then(data => {
      data.forEach(data2 => {
        if (this.poNo !== '') {
          if (this.poNo.match(data2.PO_No) && this.poNo.length === data2.PO_No.length) {
            const data ={
              parts: data2.PO_Part_List,
              PONumber: data2.PO_No,
            }
            this.matSelectPart[index] = data;

          }
        }
      });
    });
  }
  choosingPart= [];

  selectPart(j,i) {
    const samePart = this.choosingPart.find(c=>c.part.PO_Part_No === this.matSelectPart[i].parts[j].PO_Part_No && c.poNumber ===this.matSelectPart[i].PONumber);
    if(samePart){
      this.toast.warning("Same part number and PO, please choose others", "Warning");
      return;
    }
    const result = parseFloat(this.matSelectPart[i].parts[j].PO_Part_Qty) - parseFloat(this.matSelectPart[i].parts[j].POQuantity) ;
    if(result < 0){
      if( this.matSelectPart[i].parts[j].InStock + result  < 0){
        this.toast.warning("Currently the stock of the selected pack is not enough.", "Warning");
        return;
      }else{
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = '80%';
        const position = {
          top: '5%'
        };
        dialogConfig.position = position;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          partNo:this.matSelectPart[i].parts[j].PO_Part_No,
          total: result *-1,
        }
        this.dialog.open(SelectPOStockComponent, dialogConfig).afterClosed().subscribe(result => {
          if(result){
            this.matSelectPart[i].parts[j].Stocks = result;
            this.selectedParts[i] = (this.matSelectPart[i].parts[j]);
            const info = {
              part:this.matSelectPart[i].parts[j],
              poNumber: this.matSelectPart[i].PONumber
            }
            var arrayControl = this.addForm.get('packingLists') as FormArray;
            arrayControl.at(i).get('poControl').disable({ onlySelf: true });

            this.matSelectPart[i] = null;
            this.choosingPart.push(info);
          }
          else{
            return;
          }
        });
      }
    }else{
      this.selectedParts[i] = (this.matSelectPart[i].parts[j]);
      const info = {
        part:this.matSelectPart[i].parts[j],
        poNumber: this.matSelectPart[i].PONumber
      }
      var arrayControl = this.addForm.get('packingLists') as FormArray;
      arrayControl.at(i).get('poControl').disable({ onlySelf: true });

      this.matSelectPart[i] = null;
      this.choosingPart.push(info);
    }


  }

  packingLists(): FormArray {
    return this.addForm.get('packingLists') as FormArray;
  }

  newPOs(): FormGroup {
    const pofilterControl = new FormControl();
    const poControl = new FormControl();

    pofilterControl.valueChanges.subscribe(() => {
      this.findPO(pofilterControl);
      if (this.clone) {
        this.check = true;
      }

    });
    poControl.valueChanges.subscribe(() => {
      if (poControl.value) {
        this.searchPO = poControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partNumber: '',
      quantity: '',
      dateControl: new Date(),
      poControl,
      pofilterControl,
      poNo: '',
    });
  }

  findPO(po) {
    if (!this.clone) { return; }
    console.log(po.value);
    const search = po.value;
    this.matSelectPO = this.clone.filter(p => p.PO_No.toLowerCase().includes(search.toLowerCase()));
  }

  addPO() {
    this.selectedParts.push(null);
    this.matSelectPart.push(null);
    this.packingLists().push(this.newPOs());

    this.matSelectPO = cloneDeep(this.CompleteClonePO)

    this.matSelectPO.forEach(data2 => {
      data2.PO_Part_List = data2.PO_Part_List.filter(p=> p.CustomerName.includes(this.linkPackingList.Customer));
    });

    this.matSelectPO = this.matSelectPO.filter(m=>m.PO_Part_List.length > 0);

    this.clone = this.matSelectPO.slice();
  }

  removeParts(i: number) {
    this.packingLists().removeAt(i);
    this.selectedParts.splice(i, 1);
    this.choosingPart.splice(i, 1);
  }
  display(value){
    if(value> 0)
      return "+"+ value;
    return value;
  }
  view(part){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.data = part.Stocks
    this.dialog.open(InfoComponent, dialogConfig);
  }

  removeExisting(p,i){
    p.PackingList_PO_Part_List[i].isDeleted = true;
  }
  restoreExisting(p,i){
    p.PackingList_PO_Part_List[i].isDeleted = false;

  }
}
