import { element } from 'protractor';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { Customer } from '../Object_Classes/Customer/Customer';



export class CustomerDB_controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getCustomerList(): Promise<Customer[]> {
    let CustomerList: Customer[] = [];
    var snapshot = await this.db.database.ref('Customer').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const cus = new Customer();
        cus.Customer_ID = childSnapshot.key;
        cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
        cus.Contact = childSnapshot.child('_Contact').val();
        cus.Address = childSnapshot.child('_Address').val();
        cus.Email = childSnapshot.child('_Email').val();
        cus.Updated_By = childSnapshot.child('_Updated_By').val();
        cus.Currency = childSnapshot.child('_Currency').val();
        cus.Created_By = childSnapshot.child('_Created_By').val();
        cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
        cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        cus.Debtor_No = childSnapshot.child('_Debtor_No').val();
        cus.Remark = childSnapshot.child('_Remark').val();
        cus.Fax = childSnapshot.child('_Fax').val();
        cus.Area = childSnapshot.child('_Area').val();
        cus.Agent = childSnapshot.child('_Agent').val();
        cus.Term = childSnapshot.child('_Term').val();
        cus.GSTNum = childSnapshot.child('_GSTNum').val();
        CustomerList.push(cus);
      })
      ;
    }
    return CustomerList;
  }

  add_Customer(_cus: Customer,email: string) {
    _cus.Customer_Name = _cus.Customer_Name.trim();
    var updates = {}
    updates['/' + uuidv4() + '/'] = _cus;
    _cus.Created_Date = new Date();
    _cus.Last_Updated = new Date();
    if (typeof _cus.Contact === 'undefined') {
      _cus.Contact = '-';
    }
    if (typeof _cus.Email === 'undefined') {
      _cus.Email = '-';
    }
    if (typeof _cus.Person_In_Charge === 'undefined') {
      _cus.Person_In_Charge = '-';
    }
    if (typeof _cus.Debtor_No === 'undefined') {
      _cus.Debtor_No = '-';
    }
    if (typeof _cus.Remark === 'undefined') {
      _cus.Remark = '-';
    }
    if (typeof _cus.Fax === 'undefined') {
      _cus.Fax = '-';
    }
    if (typeof _cus.Area === 'undefined') {
      _cus.Area = '-';
    }
    if (typeof _cus.Agent === 'undefined') {
      _cus.Agent = '-';
    }
    if (typeof _cus.Term === 'undefined') {
      _cus.Term = '-';
    }
    if (typeof _cus.GSTNum === 'undefined') {
      _cus.GSTNum = '-';
    }
    _cus.Created_By = email;
    _cus.Updated_By = email;
    this.db.database.ref('Customer').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _cus.Customer_Name + ' has been added by the ' + email,
      date: new Date()
    };
    this.firestore.collection('CustomerLog').doc(dateFormat).set({Date: new Date()});
    this.firestore.collection('CustomerLog').doc(dateFormat).collection('Customer').add(info);
  }

  update_Customer(_cus: Customer,email: string) {
    var updates = {}
    updates['/' + _cus.Customer_ID + '/'] = _cus;
    _cus.Last_Updated = new Date();
    _cus.Updated_By = email;
    this.db.database.ref('Customer').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message:  _cus.Customer_Name + ' has been updated by the ' + email,
      date: new Date()
    };
    this.firestore.collection('CustomerLog').doc(dateFormat).set({Date: new Date()});
    this.firestore.collection('CustomerLog').doc(dateFormat).collection('Customer').add(info);
  }

  async search_Customer(id: string): Promise<Customer> {
    let searched_cus = new Customer();
    var snapshot = await this.db.database.ref('Customer').child(id).once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        searched_cus.Customer_ID = childSnapshot.key;
        searched_cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
        searched_cus.Contact = childSnapshot.child('_Contact').val();
        searched_cus.Address = childSnapshot.child('_Address').val();
        searched_cus.Email = childSnapshot.child('_Email').val();
        searched_cus.Currency = childSnapshot.child('_Currency').val();
        searched_cus.Updated_By = childSnapshot.child('_Updated_By').val();
        searched_cus.Created_By = childSnapshot.child('_Created_By').val();
        searched_cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        searched_cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
        searched_cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        searched_cus.Debtor_No = childSnapshot.child('_Debtor_No').val();
        searched_cus.Remark = childSnapshot.child('_Remark').val();
        searched_cus.Fax = childSnapshot.child('_Fax').val();
        searched_cus.Area = childSnapshot.child('_Area').val();
        searched_cus.Agent = childSnapshot.child('_Agent').val();
        searched_cus.Term = childSnapshot.child('_Term').val();
        searched_cus.GSTNum = childSnapshot.child('_GSTNum').val();
      });
    }
    return searched_cus;
  }

  async search_CustomerByName(name: string): Promise<Customer> {
    let searched_cus = new Customer();
    var snapshot = await this.db.database.ref('Customer').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Customer_Name').val() === name){
          searched_cus.Customer_ID = childSnapshot.key;
          searched_cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
          searched_cus.Contact = childSnapshot.child('_Contact').val();
          searched_cus.Address = childSnapshot.child('_Address').val();
          searched_cus.Email = childSnapshot.child('_Email').val();
          searched_cus.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_cus.Created_By = childSnapshot.child('_Created_By').val();
          searched_cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
          searched_cus.Currency = childSnapshot.child('_Currency').val();
          searched_cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          searched_cus.Debtor_No = childSnapshot.child('_Debtor_No').val();
          searched_cus.Remark = childSnapshot.child('_Remark').val();
          searched_cus.Fax = childSnapshot.child('_Fax').val();
          searched_cus.Area = childSnapshot.child('_Area').val();
          searched_cus.Agent = childSnapshot.child('_Agent').val();
          searched_cus.Term = childSnapshot.child('_Term').val();
          searched_cus.GSTNum = childSnapshot.child('_GSTNum').val();
        }
      });
    }
    return searched_cus;
  }


  async search_Partial_Customer(name: string): Promise<Customer[]> {
    let cusList: Customer[] = [];
    var snapshot = await this.db.database.ref('Customer').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_cus = new Customer();
        var get_name = childSnapshot.key;
        if (get_name.includes(name)) {
          searched_cus.Customer_ID = childSnapshot.key;
          searched_cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
          searched_cus.Contact = childSnapshot.child('_Contact').val();
          searched_cus.Address = childSnapshot.child('_Address').val();
          searched_cus.Email = childSnapshot.child('_Email').val();
          searched_cus.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_cus.Created_By = childSnapshot.child('_Created_By').val();
          searched_cus.Currency = childSnapshot.child('_Currency').val();
          searched_cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
          searched_cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          searched_cus.Debtor_No = childSnapshot.child('_Debtor_No').val();
          searched_cus.Remark = childSnapshot.child('_Remark').val();
          searched_cus.Fax = childSnapshot.child('_Fax').val();
          searched_cus.Area = childSnapshot.child('_Area').val();
          searched_cus.Agent = childSnapshot.child('_Agent').val();
          searched_cus.Term = childSnapshot.child('_Term').val();
          searched_cus.GSTNum = childSnapshot.child('_GSTNum').val();
          cusList.push(searched_cus);
        }
      });
    }

    return cusList;
  }


  delete_Customer(id: string) {
    this.db.database.ref('/Customer/' + id).set(null);
  }


}
